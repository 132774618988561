import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ReactiveFormsModule, FormsModule} from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';

// import { RouterModule, Routes } from '@angular/router';
// import { SlikaEventsResolver } from '../core/resolvers/event/slika-events.resolver';

import { SharedModule } from '../shared/shared.module';
import { MatCheckboxModule } from "@angular/material/checkbox";
import { SlikaEventComponent } from './slika-event.component';
import { SlikaEventRoutingModule } from './slika-event-routing.module';
import { EditSlikaEventDialogComponent } from './edit-slika-event-dialog/edit-slika-event-dialog.component';
import { EditCommissionsDialogComponent } from './edit-commissions-dialog/edit-commissions-dialog.component'
//import { CommissionsResolver } from '../core/resolvers/event/commissions.resolver';



@NgModule({
  declarations: [
    SlikaEventComponent,
    EditSlikaEventDialogComponent,
    EditCommissionsDialogComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    SharedModule,
    LayoutModule,
    MatCheckboxModule,
    FormsModule,
    //RouterModule.forChild(routes),  // Add this line
    SlikaEventRoutingModule,

  ],
  //providers: [CommissionsResolver] 
  //exports: [SlikaEventComponent]
  
})
export class SlikaEventModule {}
