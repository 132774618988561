import { Component, OnInit } from '@angular/core';
import { UserService } from '../../core/services/user.service';
import { WhoAmIQuery } from '../../../generated/graphql';
import { Router } from '@angular/router';

@Component({
  selector: 'app-layout-header',
  templateUrl: './layout-header.component.html',
  styleUrls: ['./layout-header.component.scss'],
})
export class LayoutHeaderComponent implements OnInit {
  currentUser: WhoAmIQuery['whoAmI'] = {} as any;
  constructor(
    public readonly user: UserService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.user.currentUser.subscribe((user) => {
      this.currentUser = user;
    });
  }

  onClickLogout() {
    this.user.logout();
    this.router.navigateByUrl('/login');
  }

  // onClickBackToEvents(){
  //   this.router.navigateByUrl('/event');
  // }
}
