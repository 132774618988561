import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { WhoAmIGQL, WhoAmIQuery } from '../../../generated/graphql';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class UserService implements OnDestroy {
  private currentUserSubject = new BehaviorSubject<WhoAmIQuery['whoAmI']>(
    {} as any
  );
  public currentUser = this.currentUserSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();
  constructor(
    private readonly whoAmIGQL: WhoAmIGQL,
    public readonly localStorageService: LocalStorageService
  ) {}

  ngOnDestroy() {
    this.currentUserSubject.complete();
    this.currentUserSubject.unsubscribe();
    this.isAuthenticatedSubject.complete();
    this.isAuthenticatedSubject.unsubscribe();
  }

  setToken(token: string, isLocal = false) {
    this.localStorageService.set('token', token, isLocal);
    this.loadUser();
  }

  loadUser() {
    const token = this.localStorageService.get('token');
    if (token) {
      this.whoAmIGQL.fetch().subscribe(
        ({ data }) => this.setAuth(data.whoAmI),
        () => this.logout()
      );
    } else {
      this.logout();
    }
  }

  private setAuth(user: WhoAmIQuery['whoAmI']) {
    // Set current user data into observable
    this.currentUserSubject.next(user);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  logout() {
    this.localStorageService.clear();
    this.currentUserSubject.next({} as any);
    this.isAuthenticatedSubject.next(false);
  }
}
