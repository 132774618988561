import { Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '../environments/environment';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { LocalStorageService } from './core/services/local-storage.service';
const STATE_KEY = makeStateKey<any>('apollo.state');

const uri = environment.serverGQL; // <-- add the URL of the GraphQL server here
export function createApollo(
  httpLink: HttpLink,
  transferState: TransferState,
  localStorageService: LocalStorageService
): ApolloClientOptions<any> {
  const auth = setContext((operation, context) => {
    const token = localStorageService.get('token');
    if (!token) {
      return {};
    } else {
      return {
        headers: {
          Authorization: token,
        },
      };
    }
  });

  const link = onError(({ graphQLErrors, networkError }) => {
    const token = localStorageService.get('token');
    if (graphQLErrors && token)
      graphQLErrors.map(({ message, locations, path }) => {
        if (message === 'Unauthorized') {
          localStorageService.clear();
          window.location.reload();
        }
      });

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const isBrowser = transferState.hasKey<any>(STATE_KEY);

  const cache = new InMemoryCache();

  const ret: ApolloClientOptions<any> = {
    link: ApolloLink.from([auth, link, httpLink.create({ uri })]),
    cache,
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only',
      },
    },
  };

  if (isBrowser) {
    const state = transferState.get<any>(STATE_KEY, null);
    cache.restore(state);
    ret.ssrForceFetchDelay = 100;
  } else {
    transferState.onSerialize(STATE_KEY, () => {
      return cache.extract();
    });
    // Reset cache after extraction to avoid sharing between requests
    cache.reset();
  }

  return ret;
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, TransferState, LocalStorageService],
    },
  ],
})
export class GraphQLModule {}
