import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SlikaEventComponent } from './slika-event.component';
import { SlikaEventsResolver } from '../core/resolvers/event/slika-events.resolver';
import { CommissionsResolver } from '../core/resolvers/event/commissions.resolver';



const routes: Routes = [
  { path: '', 
    component: SlikaEventComponent, 
    resolve: { slikaEvents: SlikaEventsResolver,
               commissionsQuery: CommissionsResolver,
    } 
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SlikaEventRoutingModule {}
