import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'count-buttons',
  templateUrl: './count-buttons.component.html',
  styleUrls: ['./count-buttons.component.scss'],
})
export class CountButtonsComponent implements OnInit {
  @Input()
  set count(count: number) {
    this.value = count;
  }

  /** Min value */
  @Input()
  min = 0;

  public value = 0;

  @Output()
  countChanged: EventEmitter<number> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onClickChangeCount(newCount: number) {
    if (newCount < this.min) {
      return;
    }

    this.value = newCount;
    this.countChanged.emit(newCount);
  }
}
