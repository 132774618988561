import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountButtonsComponent } from './count-buttons/count-buttons.component';

@NgModule({
  declarations: [CountButtonsComponent],
  imports: [CommonModule],
  exports: [CountButtonsComponent],
})
export class SharedModule {}
