import { Component, OnInit, Inject } from '@angular/core';
import { WhoAmIQuery, CommissionsQuery, UpdateCommissionsGQL, CommissionsGQL } from 'src/generated/graphql';
import { UserService } from '../../core/services/user.service';
import { MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { subscribe } from 'graphql';

interface IUpdateCommissionsInput {
  id: string;
  slikaCommission: number;
  attCommission: number;
}

@Component({
  selector: 'app-edit-commissions-dialog',
  templateUrl: './edit-commissions-dialog.component.html',
  styleUrls: ['./edit-commissions-dialog.component.scss']
})
export class EditCommissionsDialogComponent implements OnInit {
  commissionsQuery: CommissionsQuery['commissions'] = [];
  //commissionsTotal = 0;
  canEdit = false;
  user!: WhoAmIQuery['whoAmI'];
  editCommissionsForm!: FormGroup;

  constructor(
    private readonly updateCommissionsGQL: UpdateCommissionsGQL,
    private readonly commissionsGQL: CommissionsGQL,
    private readonly activatedRoute: ActivatedRoute,
    private readonly fb: FormBuilder,
    public readonly userService: UserService,
    //private readonly matDialog: MatDialog
    private readonly matDialogRef: MatDialogRef<EditCommissionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CommissionsQuery
  ) { this.commissionsQuery = this.data.commissions }

  ngOnInit(): void {
    this.userService.currentUser.subscribe((user) => {
      this.user = user;
    });

    this.commissionsGQL.fetch().subscribe((result) => {
      this.commissionsQuery = result.data.commissions;
    });
//    console.log(this.commissionsQuery);

    this.editCommissionsForm = this.fb.group({});

    for (let i = 0; i < this.commissionsQuery.length; i++) {
      this.editCommissionsForm.addControl('slikaCommission' + (i+1) + 'p', new FormControl(this.commissionsQuery[i].slikaCommission, [Validators.required]));
      this.editCommissionsForm.addControl('attCommission' + (i+1) + 'p', new FormControl(this.commissionsQuery[i].attCommission, [Validators.required]));
    }
  }

  onClickCancel(){
    this.matDialogRef.close();

  }
  
  formatDecimal(value: number): string {
    const roundedValue = Math.round(value);
    return roundedValue === value ? roundedValue.toString() : value.toFixed(2);
  }
  
  onClickUpdateCommissions() {
    const data: IUpdateCommissionsInput[] = this.commissionsQuery.map((commission, i) => ({
      id: commission.id,
      slikaCommission: parseFloat(this.editCommissionsForm.get(`slikaCommission${i + 1}p`)!.value),
    attCommission: parseFloat(this.editCommissionsForm.get(`attCommission${i + 1}p`)!.value),
    
    }));
  
    this.updateCommissionsGQL.mutate({updateCommissionsInput: data}).subscribe({
      next: (data) => {
        // handle successful update
        //console.log('successfully updated commissions: ',data);
        this.commissionsGQL.fetch().subscribe((result) => {
          this.commissionsQuery = result.data.commissions;
        });
      },
      error: (error) => {
        // handle error
        console.log('error updating commissions: ',error);
      }
    });
  }
  
}
