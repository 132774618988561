import {
  Inject,
  Injectable,
  InjectionToken,
  Injector,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  isBrowser = true;
  constructor(
    @Inject(PLATFORM_ID) platform: Object,
    private readonly cookieService: CookieService,
    @Optional() @Inject('cookies') private cookies: any
  ) {
    this.isBrowser = isPlatformBrowser(platform);
    if (!cookies) {
      this.cookies = {};
    }
  }

  get(item: string) {
    if (this.isBrowser) {
      return localStorage.getItem(item) || sessionStorage.getItem(item);
    } else {
      return this.cookies[item];
    }
  }
  set(item: string, key: any, local = false) {
    if (this.isBrowser) {
      if (local) {
        localStorage.setItem(item, key);
        this.cookieService.set(item, key, { secure: true });
      } else {
        sessionStorage.setItem(item, key);
        this.cookieService.set(item, key, {
          secure: true,
          expires: 0,
        });
      }
    }
  }
  clear() {
    if (this.isBrowser) {
      // Save popup states before clearing
      const popupStates = new Map();
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && key.startsWith('lastPopup_')) {
          popupStates.set(key, localStorage.getItem(key));
        }
      }

      // Clear storages
      localStorage.clear();
      sessionStorage.clear();

      // Restore popup states
      popupStates.forEach((value, key) => {
        if (value) localStorage.setItem(key, value);
      });
    } else {
      this.cookies = {};
    }
  }
}
