<nav class="navbar navbar-light bg-light">
  <div class="container" [class.justify-content-center]="!(user.isAuthenticated | async)">
    <a class="navbar-brand" routerLink="/">
      <img class="d-block d-lg-none" src="/assets/icons/logo.svg" alt="אטנדינג">
      <img class="d-none d-lg-block" src="/assets/icons/logo.svg" alt="אטנדינג">
    </a>
    <div *ngIf="user.isAuthenticated | async" class="d-flex">
      <span class="me-2 fw-500"> <img src="/assets/icons/user.svg" class="me-1" alt="user">שלום, {{currentUser.name}}</span>
      <span style="opacity: 0.25"> | </span>
      <!-- <span (click)="onClickBackToEvents()" class="mx-2 fw-500 cursor-pointer"> <img src="/assets/icons/flip-backward.svg" class="me-1" alt="חזרה לאירועים">חזרה לאירועים</span>
      <span style="opacity: 0.25"> | </span> -->
      <span (click)="onClickLogout()" class="ms-2 fw-500 cursor-pointer"> <img src="/assets/icons/logout.svg" class="me-1" alt="יציאה"> יציאה</span>
    </div>
  </div>
</nav>
