import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type CreateAuthInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type CreateEventInput = {
  /** Example field (placeholder) */
  exampleField: Scalars['Int'];
};

export type CreateGuestListInput = {
  attendance: Scalars['Boolean'];
  code: Scalars['String'];
  confirmationDate?: InputMaybe<Scalars['DateTime']>;
  count: Scalars['Int'];
  name: Scalars['String'];
  phone: Scalars['String'];
  status: Scalars['String'];
  tableNumber?: InputMaybe<Scalars['String']>;
};

export type CreateUserInput = {
  /** Example field (placeholder) */
  exampleField: Scalars['Int'];
};

export type Event = {
  __typename?: 'Event';
  addonAmount?: Maybe<Scalars['Float']>;
  address: Scalars['String'];
  brideFamily: Scalars['String'];
  business_event_type: Scalars['String'];
  cgTermsApproved: Scalars['Boolean'];
  cgdComplete?: Maybe<Scalars['Boolean']>;
  creditGiftsAddon: Scalars['Boolean'];
  eventDate: Scalars['DateTime'];
  eventsHall: Scalars['String'];
  extra_text: Scalars['String'];
  fileName?: Maybe<Scalars['String']>;
  fileType?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  groomFamily: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  place: Scalars['String'];
  privateEvent: Scalars['Boolean'];
  rsvp_limit: Scalars['Float'];
  sendingMessages: Scalars['Boolean'];
  smsAddon: Scalars['Boolean'];
  type: Scalars['String'];
  url: Scalars['String'];
  user: User;
  wazeShortUrl?: Maybe<Scalars['String']>;
  whatsappAddon: Scalars['Boolean'];
  whatsappFail?: Maybe<Scalars['String']>;
  wordpressId: Scalars['ID'];
};

export type GuestInput = {
  count: Scalars['Int'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type GuestList = {
  __typename?: 'GuestList';
  attCommission: Scalars['Float'];
  attendance: Scalars['Boolean'];
  confirmationDate?: Maybe<Scalars['DateTime']>;
  count: Scalars['Int'];
  eventId: Scalars['ID'];
  giftAmount: Scalars['Float'];
  giftCommission: Scalars['Float'];
  giftGiverEmail?: Maybe<Scalars['String']>;
  giftGiverMobile?: Maybe<Scalars['String']>;
  giftGiverName?: Maybe<Scalars['String']>;
  giftTermsApproved: Scalars['Boolean'];
  greeting?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
  slikaCommission: Scalars['Float'];
  smsInvite?: Maybe<Scalars['DateTime']>;
  smsReinvite?: Maybe<Scalars['DateTime']>;
  smsReminder?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  tableNumber?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  attApprovedFiles: SlikaEvent;
  buyCreditGifts: Event;
  buySmsOrWhatsapp: Event;
  createEvent: Event;
  createGuestList: GuestList;
  createToken: Scalars['String'];
  createUser: User;
  deleteGuestList: Scalars['Boolean'];
  fixGuestsWhatsappFail: Event;
  freeCreditGifts: Event;
  generateUploadUrls: Array<Scalars['String']>;
  inviteGuestsToEvent: Array<GuestList>;
  removeDocsFromSlikaEventDb: Scalars['Boolean'];
  removeEvent: Event;
  removeFileFromEvent: Event;
  removeUser: User;
  setEventPrivatePublic: Event;
  startMessaging: Array<GuestList>;
  updateAccountHolderDetails: SlikaEvent;
  updateCgdComplete: Event;
  updateCommissions: Array<Commissions>;
  updateEvent: Event;
  updateFilesInDB: SlikaEvent;
  updateGiftGiver: Scalars['Boolean'];
  updateGuestList: GuestList;
  updateRsvpLimit: Event;
  updateSendingMessages: Event;
  updateSlikaEvent: SlikaEvent;
  updateSlikaEventTotals: Scalars['Boolean'];
  updateUser: User;
  uploadFileToEvent: Scalars['String'];
  uploadSignature: Scalars['String'];
};


export type MutationAttApprovedFilesArgs = {
  eventId: Scalars['String'];
};


export type MutationBuyCreditGiftsArgs = {
  cgTermsApproved: Scalars['Boolean'];
  creditGiftsAddon: Scalars['Boolean'];
  eventId: Scalars['ID'];
  response: Scalars['String'];
};


export type MutationBuySmsOrWhatsappArgs = {
  count: Scalars['Float'];
  eventId: Scalars['ID'];
  response: Scalars['String'];
  type: Scalars['String'];
};


export type MutationCreateEventArgs = {
  createEventInput: CreateEventInput;
};


export type MutationCreateGuestListArgs = {
  createGuestListInput: CreateGuestListInput;
};


export type MutationCreateTokenArgs = {
  createAuthInput: CreateAuthInput;
};


export type MutationCreateUserArgs = {
  createUserInput: CreateUserInput;
};


export type MutationDeleteGuestListArgs = {
  id: Scalars['ID'];
};


export type MutationFixGuestsWhatsappFailArgs = {
  eventId: Scalars['String'];
  msgType: Scalars['String'];
};


export type MutationFreeCreditGiftsArgs = {
  action: Scalars['String'];
  cgTermsApproved: Scalars['Boolean'];
  creditGiftsAddon: Scalars['Boolean'];
  eventId: Scalars['ID'];
};


export type MutationGenerateUploadUrlsArgs = {
  uploadDocumentsInput: UploadDocumentsInput;
};


export type MutationInviteGuestsToEventArgs = {
  eventId: Scalars['ID'];
  guests: Array<GuestInput>;
  sendNotification: Scalars['Boolean'];
};


export type MutationRemoveDocsFromSlikaEventDbArgs = {
  uploadDocumentsInput: UploadDocumentsInput;
};


export type MutationRemoveEventArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveFileFromEventArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveUserArgs = {
  id: Scalars['Int'];
};


export type MutationSetEventPrivatePublicArgs = {
  eventId: Scalars['ID'];
  privateEvent: Scalars['Boolean'];
};


export type MutationStartMessagingArgs = {
  eventId: Scalars['ID'];
  sendNotification: Scalars['Boolean'];
};


export type MutationUpdateAccountHolderDetailsArgs = {
  updateAccountHolderDetailsInput: UpdateAccountHolderDetailsInput;
};


export type MutationUpdateCgdCompleteArgs = {
  cgdComplete: Scalars['Boolean'];
  eventId: Scalars['ID'];
};


export type MutationUpdateCommissionsArgs = {
  updateCommissionsInput: Array<UpdateCommissionsInput>;
};


export type MutationUpdateEventArgs = {
  updateEventInput: UpdateEventInput;
};


export type MutationUpdateFilesInDbArgs = {
  uploadDocumentsInput: UploadDocumentsInput;
};


export type MutationUpdateGiftGiverArgs = {
  update: UpdateGuestListInput;
};


export type MutationUpdateGuestListArgs = {
  update: UpdateGuestListInput;
};


export type MutationUpdateRsvpLimitArgs = {
  rsvpLimitInput: RsvpLimitInput;
};


export type MutationUpdateSendingMessagesArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateSlikaEventArgs = {
  updateSlikaEventInput: UpdateSlikaEventInput;
};


export type MutationUpdateSlikaEventTotalsArgs = {
  updateSlikaEventTotalsInput: UpdateSlikaEventTotalsInput;
};


export type MutationUpdateUserArgs = {
  updateUserInput: UpdateUserInput;
};


export type MutationUploadFileToEventArgs = {
  fileName: Scalars['String'];
  id: Scalars['ID'];
  size: Scalars['Float'];
};


export type MutationUploadSignatureArgs = {
  uploadSignatureInput: UploadSignatureInput;
};

export type Query = {
  __typename?: 'Query';
  commission: Commissions;
  commissions: Array<Commissions>;
  event: Event;
  eventByCode: Event;
  events: Array<Event>;
  getGiftAmountByGuestId: Scalars['Float'];
  getGuestIdByPhone?: Maybe<Scalars['String']>;
  getSubTerminalByEventId: Subterminal_Schedule;
  getUser: User;
  guestExistsByPhone: Scalars['Boolean'];
  guestList: Array<GuestList>;
  slikaEvent?: Maybe<SlikaEvent>;
  slikaEventByCode: SlikaEvent;
  slikaEvents: Array<SlikaEvent>;
  user: User;
  whoAmI: User;
};


export type QueryCommissionArgs = {
  id: Scalars['ID'];
};


export type QueryEventArgs = {
  id: Scalars['ID'];
};


export type QueryEventByCodeArgs = {
  code: Scalars['String'];
};


export type QueryGetGiftAmountByGuestIdArgs = {
  id: Scalars['String'];
};


export type QueryGetGuestIdByPhoneArgs = {
  eventId: Scalars['String'];
  phone: Scalars['String'];
};


export type QueryGetSubTerminalByEventIdArgs = {
  eventId: Scalars['String'];
};


export type QueryGetUserArgs = {
  id: Scalars['Int'];
};


export type QueryGuestExistsByPhoneArgs = {
  eventId: Scalars['String'];
  phone: Scalars['String'];
};


export type QueryGuestListArgs = {
  eventId: Scalars['ID'];
};


export type QuerySlikaEventArgs = {
  eventId: Scalars['ID'];
};


export type QuerySlikaEventByCodeArgs = {
  code: Scalars['String'];
};


export type QueryUserArgs = {
  id: Scalars['Int'];
};

export type RsvpLimitInput = {
  id: Scalars['ID'];
  rsvp_limit: Scalars['Float'];
};

export type SlikaEvent = {
  __typename?: 'SlikaEvent';
  accountBeneficiaryStatement_fileName?: Maybe<Scalars['String']>;
  accountBeneficiaryStatement_filePath?: Maybe<Scalars['String']>;
  accountBeneficiaryStatement_fileType?: Maybe<Scalars['String']>;
  accountBeneficiaryStatement_fileUrl?: Maybe<Scalars['String']>;
  accountHolderId: Scalars['String'];
  accountHolderMobile: Scalars['String'];
  accountHolderName: Scalars['String'];
  accountHolder_Id_fileName?: Maybe<Scalars['String']>;
  accountHolder_Id_filePath?: Maybe<Scalars['String']>;
  accountHolder_Id_fileType?: Maybe<Scalars['String']>;
  accountHolder_Id_fileUrl?: Maybe<Scalars['String']>;
  accountHolder_bioIdBack_fileName?: Maybe<Scalars['String']>;
  accountHolder_bioIdBack_filePath?: Maybe<Scalars['String']>;
  accountHolder_bioIdBack_fileType?: Maybe<Scalars['String']>;
  accountHolder_bioIdBack_fileUrl?: Maybe<Scalars['String']>;
  accountHolder_digitalSignature_fileName?: Maybe<Scalars['String']>;
  accountHolder_digitalSignature_filePath?: Maybe<Scalars['String']>;
  accountHolder_digitalSignature_fileType?: Maybe<Scalars['String']>;
  accountHolder_digitalSignature_fileUrl?: Maybe<Scalars['String']>;
  accountNum: Scalars['String'];
  amountGross: Scalars['Float'];
  amountNet: Scalars['Float'];
  attApprovedFiles?: Maybe<Scalars['Boolean']>;
  attCommission: Scalars['Float'];
  bankNum: Scalars['String'];
  bankStatement_fileName?: Maybe<Scalars['String']>;
  bankStatement_filePath?: Maybe<Scalars['String']>;
  bankStatement_fileType?: Maybe<Scalars['String']>;
  bankStatement_fileUrl?: Maybe<Scalars['String']>;
  branchNum: Scalars['String'];
  cgdComplete: Scalars['Boolean'];
  eventDate: Scalars['DateTime'];
  eventId: Scalars['String'];
  eventType: Scalars['String'];
  giftUrl?: Maybe<Scalars['String']>;
  idType: Scalars['String'];
  name: Scalars['String'];
  referenceNum: Scalars['String'];
  referenceNumDate?: Maybe<Scalars['DateTime']>;
  slikaCommission: Scalars['Float'];
  subTerminal?: Maybe<Scalars['String']>;
  supplierNum: Scalars['String'];
  transactionDate?: Maybe<Scalars['DateTime']>;
  transactionStatus: Scalars['Boolean'];
  user: User;
  userId: Scalars['ID'];
};

export type UpdateAccountHolderDetailsInput = {
  accountHolderId: Scalars['String'];
  accountHolderMobile: Scalars['String'];
  accountHolderName: Scalars['String'];
  accountNum: Scalars['String'];
  bankNum: Scalars['String'];
  branchNum: Scalars['String'];
  eventId: Scalars['String'];
};

export type UpdateCommissionsInput = {
  attCommission: Scalars['Float'];
  id: Scalars['ID'];
  slikaCommission: Scalars['Float'];
};

export type UpdateEventInput = {
  addon: Scalars['String'];
  addonAmount?: InputMaybe<Scalars['Int']>;
  address: Scalars['String'];
  brideFamily: Scalars['String'];
  business_event_type: Scalars['String'];
  eventDate: Scalars['DateTime'];
  eventsHall: Scalars['String'];
  extra_text: Scalars['String'];
  groomFamily: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  place: Scalars['String'];
  type: Scalars['String'];
};

export type UpdateGuestListInput = {
  attCommission?: InputMaybe<Scalars['Float']>;
  attendance?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  confirmationDate?: InputMaybe<Scalars['DateTime']>;
  count?: InputMaybe<Scalars['Int']>;
  eventId?: InputMaybe<Scalars['String']>;
  giftAmount?: InputMaybe<Scalars['Float']>;
  giftCommission?: InputMaybe<Scalars['Float']>;
  giftGiverEmail?: InputMaybe<Scalars['String']>;
  giftGiverMobile?: InputMaybe<Scalars['String']>;
  giftGiverName?: InputMaybe<Scalars['String']>;
  giftTermsApproved?: InputMaybe<Scalars['Boolean']>;
  greeting?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  slikaCommission?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['String']>;
  tableNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateSlikaEventInput = {
  accountHolderId: Scalars['String'];
  accountHolderMobile: Scalars['String'];
  accountHolderName: Scalars['String'];
  accountNum: Scalars['String'];
  amountGross?: InputMaybe<Scalars['Float']>;
  amountNet?: InputMaybe<Scalars['Float']>;
  attCommission?: InputMaybe<Scalars['Float']>;
  bankNum: Scalars['String'];
  branchNum: Scalars['String'];
  eventDate: Scalars['DateTime'];
  eventId: Scalars['String'];
  eventType: Scalars['String'];
  name: Scalars['String'];
  referenceNum: Scalars['String'];
  referenceNumDate?: InputMaybe<Scalars['DateTime']>;
  referenceNumHour?: InputMaybe<Scalars['String']>;
  referenceNumMinute?: InputMaybe<Scalars['String']>;
  slikaCommission?: InputMaybe<Scalars['Float']>;
  supplierNum: Scalars['String'];
  transactionDate: Scalars['DateTime'];
  transactionStatus: Scalars['Boolean'];
};

export type UpdateSlikaEventTotalsInput = {
  amountGross?: InputMaybe<Scalars['Float']>;
  amountNet?: InputMaybe<Scalars['Float']>;
  attCommission?: InputMaybe<Scalars['Float']>;
  eventId: Scalars['String'];
  slikaCommission?: InputMaybe<Scalars['Float']>;
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']>;
  /** Example field (placeholder) */
  exampleField?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  password?: InputMaybe<Scalars['String']>;
};

export type UploadDocumentsInput = {
  accountBeneficiaryStatement_fileName?: InputMaybe<Scalars['String']>;
  accountBeneficiaryStatement_fileSize?: InputMaybe<Scalars['Float']>;
  accountHolder_Id_fileName?: InputMaybe<Scalars['String']>;
  accountHolder_Id_fileSize?: InputMaybe<Scalars['Float']>;
  accountHolder_bioIdBack_fileName?: InputMaybe<Scalars['String']>;
  accountHolder_bioIdBack_fileSize?: InputMaybe<Scalars['Float']>;
  accountHolder_digitalSignature_fileName?: InputMaybe<Scalars['String']>;
  accountHolder_digitalSignature_fileSize?: InputMaybe<Scalars['Float']>;
  bankStatement_fileName?: InputMaybe<Scalars['String']>;
  bankStatement_fileSize?: InputMaybe<Scalars['Float']>;
  eventId: Scalars['String'];
  requiredFilesOrder: Array<Scalars['String']>;
};

export type UploadSignatureInput = {
  accountHolder_digitalSignature_fileName: Scalars['String'];
  accountHolder_digitalSignature_fileSize: Scalars['Float'];
  eventId: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  id: Scalars['String'];
  isAdmin: Scalars['Boolean'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type Commissions = {
  __typename?: 'commissions';
  attCommission: Scalars['Float'];
  baseCommission: Scalars['Float'];
  id: Scalars['ID'];
  paymentsNum: Scalars['Float'];
  slikaCommission: Scalars['Float'];
};

export type Subterminal_Schedule = {
  __typename?: 'subterminal_schedule';
  endDate?: Maybe<Scalars['DateTime']>;
  eventId: Scalars['String'];
  id: Scalars['ID'];
  startDate?: Maybe<Scalars['DateTime']>;
  subTerminal: Scalars['String'];
};

export type CreateTokenMutationVariables = Exact<{
  createAuthInput: CreateAuthInput;
}>;


export type CreateTokenMutation = { __typename?: 'Mutation', createToken: string };

export type WhoAmIQueryVariables = Exact<{ [key: string]: never; }>;


export type WhoAmIQuery = { __typename?: 'Query', whoAmI: { __typename?: 'User', id: string, name: string, email: string, isAdmin: boolean } };

export type AttApprovedFilesMutationVariables = Exact<{
  eventId: Scalars['String'];
}>;


export type AttApprovedFilesMutation = { __typename?: 'Mutation', attApprovedFiles: { __typename?: 'SlikaEvent', eventId: string, attApprovedFiles?: boolean | null } };

export type BuyCreditGiftsMutationVariables = Exact<{
  eventId: Scalars['ID'];
  creditGiftsAddon: Scalars['Boolean'];
  cgTermsApproved: Scalars['Boolean'];
  response: Scalars['String'];
}>;


export type BuyCreditGiftsMutation = { __typename?: 'Mutation', buyCreditGifts: { __typename?: 'Event', id: string, creditGiftsAddon: boolean, cgTermsApproved: boolean, eventDate: any, type: string, url: string, wordpressId: string, smsAddon: boolean, whatsappAddon: boolean, place: string, cgdComplete?: boolean | null } };

export type FreeCreditGiftsMutationVariables = Exact<{
  eventId: Scalars['ID'];
  creditGiftsAddon: Scalars['Boolean'];
  cgTermsApproved: Scalars['Boolean'];
  action: Scalars['String'];
}>;


export type FreeCreditGiftsMutation = { __typename?: 'Mutation', freeCreditGifts: { __typename?: 'Event', id: string, type: string, address: string, eventDate: any, name: string, place: string, eventsHall: string, groomFamily: string, brideFamily: string, addonAmount?: number | null, business_event_type: string, creditGiftsAddon: boolean, cgTermsApproved: boolean, smsAddon: boolean, whatsappAddon: boolean, wordpressId: string, user: { __typename?: 'User', id: string, email: string } } };

export type BuySmsOrWhatsappMutationVariables = Exact<{
  eventId: Scalars['ID'];
  type: Scalars['String'];
  count: Scalars['Float'];
  response: Scalars['String'];
}>;


export type BuySmsOrWhatsappMutation = { __typename?: 'Mutation', buySmsOrWhatsapp: { __typename?: 'Event', eventDate: any, name: string, id: string, type: string, url: string, wordpressId: string, smsAddon: boolean, whatsappAddon: boolean, place: string, cgdComplete?: boolean | null, extra_text: string, addonAmount?: number | null } };

export type CommissionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CommissionQuery = { __typename?: 'Query', commission: { __typename?: 'commissions', id: string, paymentsNum: number, baseCommission: number, slikaCommission: number, attCommission: number } };

export type CommissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type CommissionsQuery = { __typename?: 'Query', commissions: Array<{ __typename?: 'commissions', id: string, paymentsNum: number, baseCommission: number, slikaCommission: number, attCommission: number }> };

export type UpdateAccountHolderDetailsMutationVariables = Exact<{
  updateAccountHolderDetailsInput: UpdateAccountHolderDetailsInput;
}>;


export type UpdateAccountHolderDetailsMutation = { __typename?: 'Mutation', updateAccountHolderDetails: { __typename?: 'SlikaEvent', eventId: string, accountHolderName: string, accountHolderId: string, accountHolderMobile: string, accountNum: string, branchNum: string, bankNum: string } };

export type UpdateFilesInDbMutationVariables = Exact<{
  uploadDocumentsInput: UploadDocumentsInput;
}>;


export type UpdateFilesInDbMutation = { __typename?: 'Mutation', updateFilesInDB: { __typename?: 'SlikaEvent', accountHolder_Id_fileUrl?: string | null, accountHolder_Id_fileName?: string | null, accountHolder_bioIdBack_fileUrl?: string | null, accountHolder_bioIdBack_fileName?: string | null, bankStatement_fileUrl?: string | null, bankStatement_fileName?: string | null, accountBeneficiaryStatement_fileUrl?: string | null, accountBeneficiaryStatement_fileName?: string | null, accountHolder_digitalSignature_fileUrl?: string | null, accountHolder_digitalSignature_fileName?: string | null } };

export type GenerateUploadUrlsMutationVariables = Exact<{
  uploadDocumentsInput: UploadDocumentsInput;
}>;


export type GenerateUploadUrlsMutation = { __typename?: 'Mutation', generateUploadUrls: Array<string> };

export type UploadSignatureMutationVariables = Exact<{
  uploadSignatureInput: UploadSignatureInput;
}>;


export type UploadSignatureMutation = { __typename?: 'Mutation', uploadSignature: string };

export type RemoveDocsFromSlikaEventDbMutationVariables = Exact<{
  uploadDocumentsInput: UploadDocumentsInput;
}>;


export type RemoveDocsFromSlikaEventDbMutation = { __typename?: 'Mutation', removeDocsFromSlikaEventDb: boolean };

export type UpdateCgdCompleteMutationVariables = Exact<{
  eventId: Scalars['ID'];
  cgdComplete: Scalars['Boolean'];
}>;


export type UpdateCgdCompleteMutation = { __typename?: 'Mutation', updateCgdComplete: { __typename?: 'Event', id: string, creditGiftsAddon: boolean, cgTermsApproved: boolean, eventDate: any, type: string, url: string, wordpressId: string, smsAddon: boolean, whatsappAddon: boolean, place: string, cgdComplete?: boolean | null } };

export type EventQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type EventQuery = { __typename?: 'Query', event: { __typename?: 'Event', eventDate: any, id: string, type: string, url: string, wazeShortUrl?: string | null, wordpressId: string, smsAddon: boolean, whatsappAddon: boolean, addonAmount?: number | null, place: string, fileName?: string | null, fileUrl?: string | null, fileType?: string | null, business_event_type: string, name: string, rsvp_limit: number, creditGiftsAddon: boolean, cgTermsApproved: boolean, cgdComplete?: boolean | null, whatsappFail?: string | null, sendingMessages: boolean, privateEvent: boolean, extra_text: string } };

export type SlikaEventQueryVariables = Exact<{
  eventId: Scalars['ID'];
}>;


export type SlikaEventQuery = { __typename?: 'Query', slikaEvent?: { __typename?: 'SlikaEvent', eventId: string, supplierNum: string, referenceNum: string, accountHolderName: string, accountHolderId: string, accountHolderMobile: string, transactionDate?: any | null, amountGross: number, slikaCommission: number, attCommission: number, amountNet: number, transactionStatus: boolean, accountHolder_Id_fileName?: string | null, accountHolder_Id_fileType?: string | null, accountHolder_Id_fileUrl?: string | null, accountHolder_Id_filePath?: string | null, accountHolder_bioIdBack_fileName?: string | null, accountHolder_bioIdBack_fileType?: string | null, accountHolder_bioIdBack_fileUrl?: string | null, accountHolder_bioIdBack_filePath?: string | null, bankStatement_fileName?: string | null, bankStatement_fileType?: string | null, bankStatement_fileUrl?: string | null, bankStatement_filePath?: string | null, accountBeneficiaryStatement_fileName?: string | null, accountBeneficiaryStatement_fileType?: string | null, accountBeneficiaryStatement_fileUrl?: string | null, accountBeneficiaryStatement_filePath?: string | null, accountHolder_digitalSignature_fileName?: string | null, accountHolder_digitalSignature_fileType?: string | null, accountHolder_digitalSignature_fileUrl?: string | null, accountHolder_digitalSignature_filePath?: string | null, bankNum: string, accountNum: string, branchNum: string, eventType: string, eventDate: any, name: string, userId: string, idType: string, cgdComplete: boolean, giftUrl?: string | null, subTerminal?: string | null, referenceNumDate?: any | null, attApprovedFiles?: boolean | null, user: { __typename?: 'User', id: string, email: string } } | null };

export type EventByCodeQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type EventByCodeQuery = { __typename?: 'Query', eventByCode: { __typename?: 'Event', eventDate: any, type: string, id: string, url: string, wordpressId: string, address: string, name: string, place: string, fileUrl?: string | null, fileType?: string | null, business_event_type: string, rsvp_limit: number, creditGiftsAddon: boolean, cgTermsApproved: boolean, cgdComplete?: boolean | null, privateEvent: boolean } };

export type SlikaEventByCodeQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type SlikaEventByCodeQuery = { __typename?: 'Query', slikaEventByCode: { __typename?: 'SlikaEvent', supplierNum: string, amountNet: number } };

export type EventsQueryVariables = Exact<{ [key: string]: never; }>;


export type EventsQuery = { __typename?: 'Query', events: Array<{ __typename?: 'Event', id: string, eventDate: any, type: string, wordpressId: string, url: string, address: string, name: string, place: string, groomFamily: string, eventsHall: string, brideFamily: string, addonAmount?: number | null, smsAddon: boolean, whatsappAddon: boolean, fileName?: string | null, fileUrl?: string | null, fileType?: string | null, business_event_type: string, rsvp_limit: number, creditGiftsAddon: boolean, cgTermsApproved: boolean, cgdComplete?: boolean | null, whatsappFail?: string | null, extra_text: string, user: { __typename?: 'User', id: string, email: string } }> };

export type RemoveFileFromEventMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveFileFromEventMutation = { __typename?: 'Mutation', removeFileFromEvent: { __typename?: 'Event', eventDate: any, id: string, type: string, url: string, wordpressId: string, smsAddon: boolean, whatsappAddon: boolean, addonAmount?: number | null, place: string, fileName?: string | null, fileUrl?: string | null, fileType?: string | null, business_event_type: string, cgdComplete?: boolean | null } };

export type SetEventPrivatePublicMutationVariables = Exact<{
  eventId: Scalars['ID'];
  privateEvent: Scalars['Boolean'];
}>;


export type SetEventPrivatePublicMutation = { __typename?: 'Mutation', setEventPrivatePublic: { __typename?: 'Event', id: string, privateEvent: boolean } };

export type SlikaEventsQueryVariables = Exact<{ [key: string]: never; }>;


export type SlikaEventsQuery = { __typename?: 'Query', slikaEvents: Array<{ __typename?: 'SlikaEvent', supplierNum: string, referenceNum: string, accountHolderName: string, accountHolderId: string, accountHolderMobile: string, transactionDate?: any | null, amountGross: number, slikaCommission: number, attCommission: number, amountNet: number, transactionStatus: boolean, accountHolder_Id_fileName?: string | null, accountHolder_Id_fileType?: string | null, accountHolder_Id_fileUrl?: string | null, accountHolder_Id_filePath?: string | null, accountHolder_bioIdBack_fileName?: string | null, accountHolder_bioIdBack_fileType?: string | null, accountHolder_bioIdBack_fileUrl?: string | null, accountHolder_bioIdBack_filePath?: string | null, bankStatement_fileName?: string | null, bankStatement_fileType?: string | null, bankStatement_fileUrl?: string | null, bankStatement_filePath?: string | null, accountBeneficiaryStatement_fileName?: string | null, accountBeneficiaryStatement_fileType?: string | null, accountBeneficiaryStatement_fileUrl?: string | null, accountBeneficiaryStatement_filePath?: string | null, accountHolder_digitalSignature_fileName?: string | null, accountHolder_digitalSignature_fileType?: string | null, accountHolder_digitalSignature_fileUrl?: string | null, accountHolder_digitalSignature_filePath?: string | null, accountNum: string, branchNum: string, bankNum: string, eventId: string, eventType: string, eventDate: any, name: string, userId: string, cgdComplete: boolean, idType: string, giftUrl?: string | null, subTerminal?: string | null, referenceNumDate?: any | null, attApprovedFiles?: boolean | null, user: { __typename?: 'User', id: string, email: string } }> };

export type UpdateCommissionsMutationVariables = Exact<{
  updateCommissionsInput: Array<UpdateCommissionsInput> | UpdateCommissionsInput;
}>;


export type UpdateCommissionsMutation = { __typename?: 'Mutation', updateCommissions: Array<{ __typename?: 'commissions', id: string, paymentsNum: number, slikaCommission: number, attCommission: number }> };

export type UpdateEventMutationVariables = Exact<{
  updateEventInput: UpdateEventInput;
}>;


export type UpdateEventMutation = { __typename?: 'Mutation', updateEvent: { __typename?: 'Event', id: string, eventDate: any, type: string, wordpressId: string, url: string, address: string, name: string, place: string, groomFamily: string, eventsHall: string, brideFamily: string, whatsappAddon: boolean, smsAddon: boolean, addonAmount?: number | null, fileName?: string | null, fileUrl?: string | null, fileType?: string | null, business_event_type: string, rsvp_limit: number, creditGiftsAddon: boolean, cgTermsApproved: boolean, cgdComplete?: boolean | null, extra_text: string, user: { __typename?: 'User', id: string, email: string } } };

export type UpdateRsvpLimitMutationVariables = Exact<{
  rsvpLimitInput: RsvpLimitInput;
}>;


export type UpdateRsvpLimitMutation = { __typename?: 'Mutation', updateRsvpLimit: { __typename?: 'Event', rsvp_limit: number, id: string } };

export type UpdateSendingMessagesMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UpdateSendingMessagesMutation = { __typename?: 'Mutation', updateSendingMessages: { __typename?: 'Event', sendingMessages: boolean } };

export type UpdateSlikaEventMutationVariables = Exact<{
  updateSlikaEventInput: UpdateSlikaEventInput;
}>;


export type UpdateSlikaEventMutation = { __typename?: 'Mutation', updateSlikaEvent: { __typename?: 'SlikaEvent', eventId: string, supplierNum: string, referenceNum: string, accountHolderName: string, accountHolderId: string, accountHolderMobile: string, transactionDate?: any | null, amountGross: number, slikaCommission: number, attCommission: number, amountNet: number, transactionStatus: boolean, accountHolder_Id_fileName?: string | null, accountHolder_Id_fileType?: string | null, accountHolder_Id_fileUrl?: string | null, accountHolder_Id_filePath?: string | null, accountHolder_bioIdBack_fileName?: string | null, accountHolder_bioIdBack_fileType?: string | null, accountHolder_bioIdBack_fileUrl?: string | null, accountHolder_bioIdBack_filePath?: string | null, bankStatement_fileName?: string | null, bankStatement_fileType?: string | null, bankStatement_fileUrl?: string | null, bankStatement_filePath?: string | null, accountBeneficiaryStatement_fileName?: string | null, accountBeneficiaryStatement_fileType?: string | null, accountBeneficiaryStatement_fileUrl?: string | null, accountBeneficiaryStatement_filePath?: string | null, accountHolder_digitalSignature_fileName?: string | null, accountHolder_digitalSignature_fileType?: string | null, accountHolder_digitalSignature_fileUrl?: string | null, accountHolder_digitalSignature_filePath?: string | null, accountNum: string, branchNum: string, bankNum: string, eventType: string, eventDate: any, name: string, cgdComplete: boolean, idType: string, referenceNumDate?: any | null, subTerminal?: string | null, userId: string, giftUrl?: string | null, attApprovedFiles?: boolean | null } };

export type UpdateSlikaEventTotalsMutationVariables = Exact<{
  updateSlikaEventTotalsInput: UpdateSlikaEventTotalsInput;
}>;


export type UpdateSlikaEventTotalsMutation = { __typename?: 'Mutation', updateSlikaEventTotals: boolean };

export type UploadFileToEventMutationVariables = Exact<{
  id: Scalars['ID'];
  fileName: Scalars['String'];
  size: Scalars['Float'];
}>;


export type UploadFileToEventMutation = { __typename?: 'Mutation', uploadFileToEvent: string };

export type GetSubTerminalByEventIdQueryVariables = Exact<{
  eventId: Scalars['String'];
}>;


export type GetSubTerminalByEventIdQuery = { __typename?: 'Query', getSubTerminalByEventId: { __typename?: 'subterminal_schedule', subTerminal: string, startDate?: any | null, endDate?: any | null } };

export type CreateGuestListMutationVariables = Exact<{
  createGuestListInput: CreateGuestListInput;
}>;


export type CreateGuestListMutation = { __typename?: 'Mutation', createGuestList: { __typename?: 'GuestList', id: string } };

export type DeleteGuestListMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteGuestListMutation = { __typename?: 'Mutation', deleteGuestList: boolean };

export type FixGuestsWhatsappFailMutationVariables = Exact<{
  eventId: Scalars['String'];
  msgType: Scalars['String'];
}>;


export type FixGuestsWhatsappFailMutation = { __typename?: 'Mutation', fixGuestsWhatsappFail: { __typename?: 'Event', id: string, whatsappFail?: string | null } };

export type GuestListQueryVariables = Exact<{
  eventId: Scalars['ID'];
}>;


export type GuestListQuery = { __typename?: 'Query', guestList: Array<{ __typename?: 'GuestList', count: number, eventId: string, id: string, phone: string, status: string, name: string, smsInvite?: any | null, smsReinvite?: any | null, smsReminder?: any | null, tableNumber?: string | null, attendance: boolean, greeting?: string | null, giftAmount: number, giftCommission: number, confirmationDate?: any | null }> };

export type InviteGuestsToEventMutationVariables = Exact<{
  eventId: Scalars['ID'];
  guests: Array<GuestInput> | GuestInput;
  sendNotification: Scalars['Boolean'];
}>;


export type InviteGuestsToEventMutation = { __typename?: 'Mutation', inviteGuestsToEvent: Array<{ __typename?: 'GuestList', count: number, eventId: string, id: string, phone: string, status: string, name: string, smsInvite?: any | null, smsReinvite?: any | null, smsReminder?: any | null, tableNumber?: string | null, attendance: boolean, greeting?: string | null, giftAmount: number, giftCommission: number }> };

export type StartMessagingMutationVariables = Exact<{
  eventId: Scalars['ID'];
  sendNotification: Scalars['Boolean'];
}>;


export type StartMessagingMutation = { __typename?: 'Mutation', startMessaging: Array<{ __typename?: 'GuestList', count: number, eventId: string, id: string, phone: string, status: string, name: string, smsInvite?: any | null, smsReinvite?: any | null, smsReminder?: any | null, tableNumber?: string | null, attendance: boolean, greeting?: string | null, giftAmount: number, giftCommission: number, confirmationDate?: any | null }> };

export type UpdateGuestListMutationVariables = Exact<{
  update: UpdateGuestListInput;
}>;


export type UpdateGuestListMutation = { __typename?: 'Mutation', updateGuestList: { __typename?: 'GuestList', count: number, eventId: string, id: string, phone: string, status: string, name: string, smsInvite?: any | null, smsReinvite?: any | null, smsReminder?: any | null, tableNumber?: string | null, attendance: boolean } };

export type UpdateGiftGiverMutationVariables = Exact<{
  update: UpdateGuestListInput;
}>;


export type UpdateGiftGiverMutation = { __typename?: 'Mutation', updateGiftGiver: boolean };

export type GetUserQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetUserQuery = { __typename?: 'Query', getUser: { __typename?: 'User', name: string, email: string, phone: string } };

export type UpdateUserMutationVariables = Exact<{
  updateUserInput: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: string } };

export const CreateTokenDocument = gql`
    mutation createToken($createAuthInput: CreateAuthInput!) {
  createToken(createAuthInput: $createAuthInput)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTokenGQL extends Apollo.Mutation<CreateTokenMutation, CreateTokenMutationVariables> {
    document = CreateTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const WhoAmIDocument = gql`
    query whoAmI {
  whoAmI {
    id
    name
    email
    isAdmin
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class WhoAmIGQL extends Apollo.Query<WhoAmIQuery, WhoAmIQueryVariables> {
    document = WhoAmIDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AttApprovedFilesDocument = gql`
    mutation attApprovedFiles($eventId: String!) {
  attApprovedFiles(eventId: $eventId) {
    eventId
    attApprovedFiles
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AttApprovedFilesGQL extends Apollo.Mutation<AttApprovedFilesMutation, AttApprovedFilesMutationVariables> {
    document = AttApprovedFilesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BuyCreditGiftsDocument = gql`
    mutation buyCreditGifts($eventId: ID!, $creditGiftsAddon: Boolean!, $cgTermsApproved: Boolean!, $response: String!) {
  buyCreditGifts(
    eventId: $eventId
    creditGiftsAddon: $creditGiftsAddon
    cgTermsApproved: $cgTermsApproved
    response: $response
  ) {
    id
    creditGiftsAddon
    cgTermsApproved
    eventDate
    type
    url
    wordpressId
    smsAddon
    whatsappAddon
    place
    cgdComplete
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BuyCreditGiftsGQL extends Apollo.Mutation<BuyCreditGiftsMutation, BuyCreditGiftsMutationVariables> {
    document = BuyCreditGiftsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FreeCreditGiftsDocument = gql`
    mutation freeCreditGifts($eventId: ID!, $creditGiftsAddon: Boolean!, $cgTermsApproved: Boolean!, $action: String!) {
  freeCreditGifts(
    eventId: $eventId
    creditGiftsAddon: $creditGiftsAddon
    cgTermsApproved: $cgTermsApproved
    action: $action
  ) {
    id
    type
    address
    eventDate
    name
    user {
      id
      email
    }
    place
    eventsHall
    groomFamily
    brideFamily
    addonAmount
    business_event_type
    creditGiftsAddon
    cgTermsApproved
    smsAddon
    whatsappAddon
    wordpressId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FreeCreditGiftsGQL extends Apollo.Mutation<FreeCreditGiftsMutation, FreeCreditGiftsMutationVariables> {
    document = FreeCreditGiftsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BuySmsOrWhatsappDocument = gql`
    mutation buySmsOrWhatsapp($eventId: ID!, $type: String!, $count: Float!, $response: String!) {
  buySmsOrWhatsapp(
    eventId: $eventId
    type: $type
    count: $count
    response: $response
  ) {
    eventDate
    name
    id
    type
    url
    wordpressId
    smsAddon
    whatsappAddon
    place
    cgdComplete
    extra_text
    addonAmount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BuySmsOrWhatsappGQL extends Apollo.Mutation<BuySmsOrWhatsappMutation, BuySmsOrWhatsappMutationVariables> {
    document = BuySmsOrWhatsappDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CommissionDocument = gql`
    query commission($id: ID!) {
  commission(id: $id) {
    id
    paymentsNum
    baseCommission
    slikaCommission
    attCommission
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CommissionGQL extends Apollo.Query<CommissionQuery, CommissionQueryVariables> {
    document = CommissionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CommissionsDocument = gql`
    query commissions {
  commissions {
    id
    paymentsNum
    baseCommission
    slikaCommission
    attCommission
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CommissionsGQL extends Apollo.Query<CommissionsQuery, CommissionsQueryVariables> {
    document = CommissionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAccountHolderDetailsDocument = gql`
    mutation updateAccountHolderDetails($updateAccountHolderDetailsInput: UpdateAccountHolderDetailsInput!) {
  updateAccountHolderDetails(
    updateAccountHolderDetailsInput: $updateAccountHolderDetailsInput
  ) {
    eventId
    accountHolderName
    accountHolderId
    accountHolderMobile
    accountNum
    branchNum
    bankNum
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAccountHolderDetailsGQL extends Apollo.Mutation<UpdateAccountHolderDetailsMutation, UpdateAccountHolderDetailsMutationVariables> {
    document = UpdateAccountHolderDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateFilesInDbDocument = gql`
    mutation updateFilesInDB($uploadDocumentsInput: UploadDocumentsInput!) {
  updateFilesInDB(uploadDocumentsInput: $uploadDocumentsInput) {
    accountHolder_Id_fileUrl
    accountHolder_Id_fileName
    accountHolder_bioIdBack_fileUrl
    accountHolder_bioIdBack_fileName
    bankStatement_fileUrl
    bankStatement_fileName
    accountBeneficiaryStatement_fileUrl
    accountBeneficiaryStatement_fileName
    accountHolder_digitalSignature_fileUrl
    accountHolder_digitalSignature_fileName
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateFilesInDbGQL extends Apollo.Mutation<UpdateFilesInDbMutation, UpdateFilesInDbMutationVariables> {
    document = UpdateFilesInDbDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GenerateUploadUrlsDocument = gql`
    mutation generateUploadUrls($uploadDocumentsInput: UploadDocumentsInput!) {
  generateUploadUrls(uploadDocumentsInput: $uploadDocumentsInput)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GenerateUploadUrlsGQL extends Apollo.Mutation<GenerateUploadUrlsMutation, GenerateUploadUrlsMutationVariables> {
    document = GenerateUploadUrlsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadSignatureDocument = gql`
    mutation uploadSignature($uploadSignatureInput: UploadSignatureInput!) {
  uploadSignature(uploadSignatureInput: $uploadSignatureInput)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadSignatureGQL extends Apollo.Mutation<UploadSignatureMutation, UploadSignatureMutationVariables> {
    document = UploadSignatureDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveDocsFromSlikaEventDbDocument = gql`
    mutation removeDocsFromSlikaEventDb($uploadDocumentsInput: UploadDocumentsInput!) {
  removeDocsFromSlikaEventDb(uploadDocumentsInput: $uploadDocumentsInput)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveDocsFromSlikaEventDbGQL extends Apollo.Mutation<RemoveDocsFromSlikaEventDbMutation, RemoveDocsFromSlikaEventDbMutationVariables> {
    document = RemoveDocsFromSlikaEventDbDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCgdCompleteDocument = gql`
    mutation UpdateCgdComplete($eventId: ID!, $cgdComplete: Boolean!) {
  updateCgdComplete(eventId: $eventId, cgdComplete: $cgdComplete) {
    id
    creditGiftsAddon
    cgTermsApproved
    eventDate
    type
    url
    wordpressId
    smsAddon
    whatsappAddon
    place
    cgdComplete
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCgdCompleteGQL extends Apollo.Mutation<UpdateCgdCompleteMutation, UpdateCgdCompleteMutationVariables> {
    document = UpdateCgdCompleteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EventDocument = gql`
    query event($id: ID!) {
  event(id: $id) {
    eventDate
    id
    type
    url
    wazeShortUrl
    wordpressId
    smsAddon
    whatsappAddon
    addonAmount
    place
    fileName
    fileUrl
    fileType
    business_event_type
    name
    rsvp_limit
    creditGiftsAddon
    cgTermsApproved
    cgdComplete
    whatsappFail
    sendingMessages
    privateEvent
    extra_text
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EventGQL extends Apollo.Query<EventQuery, EventQueryVariables> {
    document = EventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SlikaEventDocument = gql`
    query slikaEvent($eventId: ID!) {
  slikaEvent(eventId: $eventId) {
    eventId
    supplierNum
    referenceNum
    accountHolderName
    accountHolderId
    accountHolderMobile
    transactionDate
    amountGross
    slikaCommission
    attCommission
    amountNet
    transactionStatus
    accountHolder_Id_fileName
    accountHolder_Id_fileType
    accountHolder_Id_fileUrl
    accountHolder_Id_filePath
    accountHolder_bioIdBack_fileName
    accountHolder_bioIdBack_fileType
    accountHolder_bioIdBack_fileUrl
    accountHolder_bioIdBack_filePath
    bankStatement_fileName
    bankStatement_fileType
    bankStatement_fileUrl
    bankStatement_filePath
    accountBeneficiaryStatement_fileName
    accountBeneficiaryStatement_fileType
    accountBeneficiaryStatement_fileUrl
    accountBeneficiaryStatement_filePath
    accountHolder_digitalSignature_fileName
    accountHolder_digitalSignature_fileType
    accountHolder_digitalSignature_fileUrl
    accountHolder_digitalSignature_filePath
    bankNum
    accountNum
    branchNum
    eventType
    eventDate
    name
    userId
    idType
    cgdComplete
    giftUrl
    user {
      id
      email
    }
    subTerminal
    referenceNumDate
    attApprovedFiles
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SlikaEventGQL extends Apollo.Query<SlikaEventQuery, SlikaEventQueryVariables> {
    document = SlikaEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EventByCodeDocument = gql`
    query eventByCode($code: String!) {
  eventByCode(code: $code) {
    eventDate
    type
    id
    url
    wordpressId
    address
    name
    place
    fileUrl
    fileType
    business_event_type
    rsvp_limit
    creditGiftsAddon
    cgTermsApproved
    cgdComplete
    privateEvent
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EventByCodeGQL extends Apollo.Query<EventByCodeQuery, EventByCodeQueryVariables> {
    document = EventByCodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SlikaEventByCodeDocument = gql`
    query slikaEventByCode($code: String!) {
  slikaEventByCode(code: $code) {
    supplierNum
    amountNet
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SlikaEventByCodeGQL extends Apollo.Query<SlikaEventByCodeQuery, SlikaEventByCodeQueryVariables> {
    document = SlikaEventByCodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EventsDocument = gql`
    query events {
  events {
    id
    eventDate
    type
    wordpressId
    url
    address
    name
    place
    groomFamily
    eventsHall
    brideFamily
    addonAmount
    smsAddon
    whatsappAddon
    fileName
    fileUrl
    fileType
    business_event_type
    rsvp_limit
    creditGiftsAddon
    cgTermsApproved
    cgdComplete
    user {
      id
      email
    }
    whatsappFail
    extra_text
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EventsGQL extends Apollo.Query<EventsQuery, EventsQueryVariables> {
    document = EventsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveFileFromEventDocument = gql`
    mutation removeFileFromEvent($id: ID!) {
  removeFileFromEvent(id: $id) {
    eventDate
    id
    type
    url
    wordpressId
    smsAddon
    whatsappAddon
    addonAmount
    place
    fileName
    fileUrl
    fileType
    business_event_type
    cgdComplete
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveFileFromEventGQL extends Apollo.Mutation<RemoveFileFromEventMutation, RemoveFileFromEventMutationVariables> {
    document = RemoveFileFromEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetEventPrivatePublicDocument = gql`
    mutation setEventPrivatePublic($eventId: ID!, $privateEvent: Boolean!) {
  setEventPrivatePublic(eventId: $eventId, privateEvent: $privateEvent) {
    id
    privateEvent
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetEventPrivatePublicGQL extends Apollo.Mutation<SetEventPrivatePublicMutation, SetEventPrivatePublicMutationVariables> {
    document = SetEventPrivatePublicDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SlikaEventsDocument = gql`
    query slikaEvents {
  slikaEvents {
    supplierNum
    referenceNum
    accountHolderName
    accountHolderId
    accountHolderMobile
    transactionDate
    amountGross
    slikaCommission
    attCommission
    amountNet
    transactionStatus
    accountHolder_Id_fileName
    accountHolder_Id_fileType
    accountHolder_Id_fileUrl
    accountHolder_Id_filePath
    accountHolder_bioIdBack_fileName
    accountHolder_bioIdBack_fileType
    accountHolder_bioIdBack_fileUrl
    accountHolder_bioIdBack_filePath
    bankStatement_fileName
    bankStatement_fileType
    bankStatement_fileUrl
    bankStatement_filePath
    accountBeneficiaryStatement_fileName
    accountBeneficiaryStatement_fileType
    accountBeneficiaryStatement_fileUrl
    accountBeneficiaryStatement_filePath
    accountHolder_digitalSignature_fileName
    accountHolder_digitalSignature_fileType
    accountHolder_digitalSignature_fileUrl
    accountHolder_digitalSignature_filePath
    accountNum
    branchNum
    bankNum
    eventId
    eventType
    eventDate
    name
    userId
    cgdComplete
    idType
    giftUrl
    user {
      id
      email
    }
    subTerminal
    referenceNumDate
    attApprovedFiles
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SlikaEventsGQL extends Apollo.Query<SlikaEventsQuery, SlikaEventsQueryVariables> {
    document = SlikaEventsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCommissionsDocument = gql`
    mutation updateCommissions($updateCommissionsInput: [UpdateCommissionsInput!]!) {
  updateCommissions(updateCommissionsInput: $updateCommissionsInput) {
    id
    paymentsNum
    slikaCommission
    attCommission
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCommissionsGQL extends Apollo.Mutation<UpdateCommissionsMutation, UpdateCommissionsMutationVariables> {
    document = UpdateCommissionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEventDocument = gql`
    mutation updateEvent($updateEventInput: UpdateEventInput!) {
  updateEvent(updateEventInput: $updateEventInput) {
    id
    eventDate
    type
    wordpressId
    url
    address
    name
    place
    groomFamily
    eventsHall
    brideFamily
    whatsappAddon
    smsAddon
    addonAmount
    fileName
    fileUrl
    fileType
    business_event_type
    rsvp_limit
    creditGiftsAddon
    cgTermsApproved
    cgdComplete
    user {
      id
      email
    }
    extra_text
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEventGQL extends Apollo.Mutation<UpdateEventMutation, UpdateEventMutationVariables> {
    document = UpdateEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateRsvpLimitDocument = gql`
    mutation updateRsvpLimit($rsvpLimitInput: RsvpLimitInput!) {
  updateRsvpLimit(rsvpLimitInput: $rsvpLimitInput) {
    rsvp_limit
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateRsvpLimitGQL extends Apollo.Mutation<UpdateRsvpLimitMutation, UpdateRsvpLimitMutationVariables> {
    document = UpdateRsvpLimitDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSendingMessagesDocument = gql`
    mutation updateSendingMessages($id: ID!) {
  updateSendingMessages(id: $id) {
    sendingMessages
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSendingMessagesGQL extends Apollo.Mutation<UpdateSendingMessagesMutation, UpdateSendingMessagesMutationVariables> {
    document = UpdateSendingMessagesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSlikaEventDocument = gql`
    mutation updateSlikaEvent($updateSlikaEventInput: UpdateSlikaEventInput!) {
  updateSlikaEvent(updateSlikaEventInput: $updateSlikaEventInput) {
    eventId
    supplierNum
    referenceNum
    accountHolderName
    accountHolderId
    accountHolderMobile
    transactionDate
    amountGross
    slikaCommission
    attCommission
    amountNet
    transactionStatus
    accountHolder_Id_fileName
    accountHolder_Id_fileType
    accountHolder_Id_fileUrl
    accountHolder_Id_filePath
    accountHolder_bioIdBack_fileName
    accountHolder_bioIdBack_fileType
    accountHolder_bioIdBack_fileUrl
    accountHolder_bioIdBack_filePath
    bankStatement_fileName
    bankStatement_fileType
    bankStatement_fileUrl
    bankStatement_filePath
    accountBeneficiaryStatement_fileName
    accountBeneficiaryStatement_fileType
    accountBeneficiaryStatement_fileUrl
    accountBeneficiaryStatement_filePath
    accountHolder_digitalSignature_fileName
    accountHolder_digitalSignature_fileType
    accountHolder_digitalSignature_fileUrl
    accountHolder_digitalSignature_filePath
    accountNum
    branchNum
    bankNum
    eventType
    eventDate
    name
    cgdComplete
    idType
    referenceNumDate
    subTerminal
    userId
    giftUrl
    attApprovedFiles
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSlikaEventGQL extends Apollo.Mutation<UpdateSlikaEventMutation, UpdateSlikaEventMutationVariables> {
    document = UpdateSlikaEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSlikaEventTotalsDocument = gql`
    mutation updateSlikaEventTotals($updateSlikaEventTotalsInput: UpdateSlikaEventTotalsInput!) {
  updateSlikaEventTotals(
    updateSlikaEventTotalsInput: $updateSlikaEventTotalsInput
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSlikaEventTotalsGQL extends Apollo.Mutation<UpdateSlikaEventTotalsMutation, UpdateSlikaEventTotalsMutationVariables> {
    document = UpdateSlikaEventTotalsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadFileToEventDocument = gql`
    mutation uploadFileToEvent($id: ID!, $fileName: String!, $size: Float!) {
  uploadFileToEvent(id: $id, fileName: $fileName, size: $size)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadFileToEventGQL extends Apollo.Mutation<UploadFileToEventMutation, UploadFileToEventMutationVariables> {
    document = UploadFileToEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSubTerminalByEventIdDocument = gql`
    query getSubTerminalByEventId($eventId: String!) {
  getSubTerminalByEventId(eventId: $eventId) {
    subTerminal
    startDate
    endDate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSubTerminalByEventIdGQL extends Apollo.Query<GetSubTerminalByEventIdQuery, GetSubTerminalByEventIdQueryVariables> {
    document = GetSubTerminalByEventIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateGuestListDocument = gql`
    mutation createGuestList($createGuestListInput: CreateGuestListInput!) {
  createGuestList(createGuestListInput: $createGuestListInput) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateGuestListGQL extends Apollo.Mutation<CreateGuestListMutation, CreateGuestListMutationVariables> {
    document = CreateGuestListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteGuestListDocument = gql`
    mutation deleteGuestList($id: ID!) {
  deleteGuestList(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteGuestListGQL extends Apollo.Mutation<DeleteGuestListMutation, DeleteGuestListMutationVariables> {
    document = DeleteGuestListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FixGuestsWhatsappFailDocument = gql`
    mutation fixGuestsWhatsappFail($eventId: String!, $msgType: String!) {
  fixGuestsWhatsappFail(eventId: $eventId, msgType: $msgType) {
    id
    whatsappFail
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FixGuestsWhatsappFailGQL extends Apollo.Mutation<FixGuestsWhatsappFailMutation, FixGuestsWhatsappFailMutationVariables> {
    document = FixGuestsWhatsappFailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GuestListDocument = gql`
    query guestList($eventId: ID!) {
  guestList(eventId: $eventId) {
    count
    eventId
    id
    phone
    status
    name
    smsInvite
    smsReinvite
    smsReminder
    tableNumber
    attendance
    greeting
    giftAmount
    giftCommission
    confirmationDate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GuestListGQL extends Apollo.Query<GuestListQuery, GuestListQueryVariables> {
    document = GuestListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InviteGuestsToEventDocument = gql`
    mutation inviteGuestsToEvent($eventId: ID!, $guests: [GuestInput!]!, $sendNotification: Boolean!) {
  inviteGuestsToEvent(
    eventId: $eventId
    guests: $guests
    sendNotification: $sendNotification
  ) {
    count
    eventId
    id
    phone
    status
    name
    smsInvite
    smsReinvite
    smsReminder
    tableNumber
    attendance
    greeting
    giftAmount
    giftCommission
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InviteGuestsToEventGQL extends Apollo.Mutation<InviteGuestsToEventMutation, InviteGuestsToEventMutationVariables> {
    document = InviteGuestsToEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StartMessagingDocument = gql`
    mutation startMessaging($eventId: ID!, $sendNotification: Boolean!) {
  startMessaging(eventId: $eventId, sendNotification: $sendNotification) {
    count
    eventId
    id
    phone
    status
    name
    smsInvite
    smsReinvite
    smsReminder
    tableNumber
    attendance
    greeting
    giftAmount
    giftCommission
    confirmationDate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StartMessagingGQL extends Apollo.Mutation<StartMessagingMutation, StartMessagingMutationVariables> {
    document = StartMessagingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateGuestListDocument = gql`
    mutation updateGuestList($update: UpdateGuestListInput!) {
  updateGuestList(update: $update) {
    count
    eventId
    id
    phone
    status
    name
    smsInvite
    smsReinvite
    smsReminder
    tableNumber
    attendance
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateGuestListGQL extends Apollo.Mutation<UpdateGuestListMutation, UpdateGuestListMutationVariables> {
    document = UpdateGuestListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateGiftGiverDocument = gql`
    mutation updateGiftGiver($update: UpdateGuestListInput!) {
  updateGiftGiver(update: $update)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateGiftGiverGQL extends Apollo.Mutation<UpdateGiftGiverMutation, UpdateGiftGiverMutationVariables> {
    document = UpdateGiftGiverDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserDocument = gql`
    query getUser($id: Int!) {
  getUser(id: $id) {
    name
    email
    phone
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserGQL extends Apollo.Query<GetUserQuery, GetUserQueryVariables> {
    document = GetUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDocument = gql`
    mutation updateUser($updateUserInput: UpdateUserInput!) {
  updateUser(updateUserInput: $updateUserInput) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    document = UpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }