import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SlikaEventsQuery, WhoAmIQuery, CommissionsQuery } from '../../generated/graphql';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../core/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { EditSlikaEventDialogComponent } from './edit-slika-event-dialog/edit-slika-event-dialog.component';
import { cloneDeep } from '@apollo/client/utilities';
import { EditCommissionsDialogComponent } from './edit-commissions-dialog/edit-commissions-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-slika-event',
  templateUrl: './slika-event.component.html',
  styleUrls: ['./slika-event.component.scss']
})
export class SlikaEventComponent implements OnInit {

  commissionsQuery: CommissionsQuery['commissions'] = [];
  slikaEvents: SlikaEventsQuery['slikaEvents'] = [];
  user!: WhoAmIQuery['whoAmI'];
  displayedSlikaEvents: any[] = [];
  // Pagination Settings
  pageSize: number = 10;
  pageIndex: number = 0; // 0 based index. 0 is the first page
  sortColumn: string = 'eventDate';
  sortDirection: 'asc' | 'desc' | '' = '';
  filterQuery: string = '';

  //toggle = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    public readonly userService: UserService,
    private readonly matDialog: MatDialog,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    //console.log("slika-minisite initializing...");
    this.userService.currentUser.subscribe((user) => {
      this.user = user;
    });
    this.activatedRoute.data.subscribe((data) => {
      this.slikaEvents = cloneDeep(data.slikaEvents);
      this.commissionsQuery = data.commissionsQuery;  
      this.updateDisplayedSlikaEvents();

      //console.log('SLIKA EVENTS SUBSCRIBED: ' + this.slikaEvents);
    });
    
  }

  onClickGotoEventComponent(){
    this.router.navigate(['/event']);
  }

  isSorted(column: string): boolean {
    return column === this.sortColumn;
  }

  updateDisplayedSlikaEvents(): void {
    if(this.pageIndex*this.pageSize >= this.slikaEvents.length) {
      this.pageIndex = Math.floor((this.slikaEvents.length-1) / this.pageSize);
    }
    
    let sortedEvents = [...this.slikaEvents];

    // if (this.filterQuery) {
    //   sortedEvents = sortedEvents.filter(event =>
    //     Object.values(event).some(val =>
    //       val?.toString().toLowerCase().includes(this.filterQuery.toLowerCase())
    //     )
    //   );
    // }

    if (this.filterQuery) {
      sortedEvents = sortedEvents.filter(event =>
        event.eventId?.toString().toLowerCase().includes(this.filterQuery.toLowerCase()) ||
        event.supplierNum?.toString().toLowerCase().includes(this.filterQuery.toLowerCase()) ||
        event.name?.toString().toLowerCase().includes(this.filterQuery.toLowerCase())
      );
    }

    if (this.sortColumn) {
      sortedEvents.sort((a, b) => {
        let aValue: any = a[this.sortColumn as keyof typeof a] || '';
        let bValue: any = b[this.sortColumn as keyof typeof b] || '';

        if (['supplierNum', 'eventId', 'referenceNum'].includes(this.sortColumn)) {
          aValue = aValue !== '' ? parseInt(aValue, 10) : (this.sortDirection === 'asc' ? Infinity : -Infinity);
          bValue = bValue !== '' ? parseInt(bValue, 10) : (this.sortDirection === 'asc' ? Infinity : -Infinity);
        }

        if (this.sortColumn === 'eventDate' || this.sortColumn === 'transactionDate') {
          aValue = new Date(aValue);
          bValue = new Date(bValue);
        }

        if (aValue < bValue) {
          return this.sortDirection === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return this.sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
  
    let startIndex = this.pageIndex * this.pageSize;
    let endIndex = startIndex + this.pageSize;
    this.displayedSlikaEvents = sortedEvents.slice(startIndex, endIndex);
  }

  
  
  onSort(column: string): void {
    if (this.sortColumn === column) {
      // Change sort direction
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      // Change sort column and default to ascending
      this.sortColumn = column;
      this.sortDirection = 'asc';
    }
    this.updateDisplayedSlikaEvents();
  }

  firstPage(): void {
    this.pageIndex = 0;
    this.updateDisplayedSlikaEvents();
  }

  previousPage(): void {
    if (this.pageIndex > 0) {
      this.pageIndex--;
      this.updateDisplayedSlikaEvents();
    }
  }
  
  nextPage(): void {
    if (this.pageIndex < Math.floor((this.slikaEvents.length - 1) / this.pageSize)) {
        this.pageIndex++;
        this.updateDisplayedSlikaEvents();
    }
}

  lastPage(): void {
    this.pageIndex = Math.floor((this.slikaEvents.length - 1) / this.pageSize);
    this.updateDisplayedSlikaEvents();
}
  
  getEndIndex(): number {
    return Math.min((this.pageIndex + 1) * this.pageSize, this.slikaEvents.length);
  }

  getPagesNum(): number {
    return Math.ceil(this.slikaEvents.length / this.pageSize)
  }
  
  async onClickEditSlikaEvent(slikaEvent: SlikaEventsQuery['slikaEvents'][0]) {
    this.matDialog
      .open(EditSlikaEventDialogComponent, {
        data: { slikaEvent, user: this.user },
        maxWidth: '700px' 
      })
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          const indexSlikaEvent = this.slikaEvents.findIndex((v) => v.eventId === slikaEvent.eventId);
          this.slikaEvents[indexSlikaEvent] = cloneDeep(data);
          this.updateDisplayedSlikaEvents();

        }
      });
  }

  async onClickEditCommissions(){
    //console.log('onClickEdit: ',this.commissionsQuery); // add this line

    this.matDialog
      .open(EditCommissionsDialogComponent,{
        data: { 
          commissions: this.commissionsQuery
          
        }, autoFocus: false

      })
      .afterClosed()
      .subscribe(result => {
        //console.log('The dialog was closed');
        // handle any operations after closing dialog if needed
    });

  }
  // example matdialog for editing data
  // async onClickEditEvent(event: EventsQuery['events'][0]) {
  //   this.matDialog
  //     .open(EditEventDialogComponent, { data: event, maxWidth: '700px' })
  //     .afterClosed()
  //     .subscribe((data) => {
  //       if (data) {
  //         const indexEvent = this.events.findIndex((v) => v.id === event.id);
  //         this.events[indexEvent] = cloneDeep(data);
  //       }
  //     });
  // }

}
