
    <div class="container-fluid p-0 invite-guests-dialog">
    <div
      mat-dialog-title
      class="mt-4 mx-4 d-flex justify-content-between align-items-center"
    >
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col">
            <div  class="d-flex " style="flex-direction: column; justify-content: center;">
              <h1 class="font-FbMatador-Bold mb-0" style="font-size: 37px">
                טבלת עמלות
              </h1>
              <!-- <h2 class="fw-normal mb-0" style="font-size: 14px;">
                הצטרפו עכשיו לשירות ותקבלו את המתנות תוך 5 ימים ממועד האירוע!
              </h2> -->
            </div>
          </div>
        </div>
     </div>

      <!-- <button
        type="button"
        class="btn btn-primary"
        mat-dialog-close
        title="סגירה"
      >
        <img src="/assets/icons/close.svg" alt="סגירה" />
      </button> -->
    </div>

    <mat-dialog-content
      MatDialogContent
      class="dialog-content attending-scroll"
    ><!--style="height: 80%;"-->
      <ng-container [ngSwitch]="user.isAdmin">
        <ng-container *ngIf="editCommissionsForm">
        <div  *ngSwitchCase="true"> <!-- STEP 1 -->
          <form [formGroup]="editCommissionsForm" style="height: 94%">
          <!-- <h1 class="font-FbMatador-Bold mb-2 step-title text-center">
            הזנת פרטי תשלום
          </h1>
          <h4 class="text-center" style="font-size: 15px">
            סליקה מאובטחת בתקן PCI DSS
          </h4> -->
          <table class="table" style="border-collapse: unset">
            <thead class="fw-500">
              <tr>
                <td class="w-20">תשלומים</td>
                <td class="w-20">בסיס</td>
                <td class="w-20">איגוד הסליקה</td>
                <td class="w-20">אטנדינג</td>
                <td class="w-20">סה"כ</td>
              </tr>
            </thead>
            <tbody class="fw-normal">
                <tr *ngFor="let commission of commissionsQuery; let i = index">
                  <td class="vertical-center">{{ commission.paymentsNum }}</td>
                  <td class="vertical-center">% {{ commission.baseCommission }}</td>
                  <td>
                    <div class="input-wrapper">
                        <span class="percentage-symbol">%</span>
                        <input style="border-color: white;" class="ps-0 w-100 form-control" [formControlName]="'slikaCommission' + (i+1) + 'p'" />
                      
                    </div>
                  </td>
                  <td>
                    <div class="input-wrapper">
                        <span class="percentage-symbol">%</span>
                      <input style="border-color: white;" class="ps-0 w-100 form-control" [formControlName]="'attCommission' + (i+1) + 'p'" />
                      
                    </div>
                  </td>
                  <td class="vertical-center">% {{ formatDecimal(commission.baseCommission + commission.slikaCommission + commission.attCommission) }}</td>
                </tr>
              </tbody>
          </table>
        </form>
      </div> 
    </ng-container>
      <div  *ngSwitchCase="false"> <!-- STEP 1 -->
        <!-- <form [formGroup]="editCommissionsForm" style="height: 94%"> -->
        <!-- <h1 class="font-FbMatador-Bold mb-2 step-title text-center">
          הזנת פרטי תשלום
        </h1>
        <h4 class="text-center" style="font-size: 15px">
          סליקה מאובטחת בתקן PCI DSS
        </h4> -->
        <table class="table" style="border-collapse: unset">
          <thead class="fw-500">
            <tr>
              <td class="text-center">תשלומים</td>
              <td class="text-center">בסיס</td>
              <td class="text-center">איגוד הסליקה</td>
              <td class="text-center">אטנדינג</td>
              <td class="text-center">סה"כ</td>
            </tr>
          </thead>
          <tbody class="fw-normal">
            <tr *ngFor="let commission of commissionsQuery; let i = index">
              <td>{{ commission.paymentsNum }}</td>
              <td>{{ commission.baseCommission }}</td>
              <td>{{ commission.slikaCommission }}</td>
              <td>{{ commission.attCommission }}</td>
              <td>{{ commission.baseCommission + commission.slikaCommission + commission.attCommission }}</td>
            </tr>
          </tbody>
        </table>
      <!-- </form> -->
    </div>       
      </ng-container>
    </mat-dialog-content>

    <mat-dialog-actions class="justify-content-between mb-4 pt-0">
      <button
      type="button"
      class="btn btn-outline-primary px-lg-5 px-lg-2 fs-4 me-2"
      style="margin-right: 24px;"
      (click)="onClickCancel()"
    >
      יציאה</button>
      <!-- was in the button: [disabled]="!canEdit"-->  
      <button *ngIf="user.isAdmin"
      
      type="button"
      class="btn btn-primary px-lg-5 px-lg-2 fs-4"
      (click)="onClickUpdateCommissions()"
      style="
            margin-left: 24px;
            background-color: #ee2768;
            border-color: #ee2768;
            color: white;
            white-space: nowrap;
          ">עדכן</button>
      
    </mat-dialog-actions>
  </div>

