<div class="container-fluid">
  <div class="row">
    <div class="col">
      <h1 class="fs-1 mt-5 text-center">כניסה למערכת</h1>
      <form
        [formGroup]="loginForm"
        (ngSubmit)="onSubmitLoginForm()"
        style="max-width: 400px; margin: auto"
      >
        <div class="alert alert-danger mt-3" [class.d-none]="!showError" role="alert">
          האימייל או הסיסמה אינם נכונים ({{count}})
        </div>
        <div class="mb-3">
          <label for="email" class="form-label">כתובת אימייל</label>
          <input
            type="email"
            formControlName="email"
            class="form-control"
            id="email"
            [class.is-invalid]="email.dirty && email.invalid"
            [class.is-valid]="email.dirty && email.valid"
          />
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">סיסמה</label>
          <input
            type="password"
            formControlName="password"
            class="form-control"
            id="password"
            [class.is-invalid]="password.dirty && password.invalid"
            [class.is-valid]="password.dirty && password.valid"
          />
        </div>
        <div class="mb-3 form-check">
          <input
            type="checkbox"
            formControlName="rememberMe"
            class="form-check-input"
            id="remember-me"
          />
          <label class="form-check-label" for="remember-me"> זכור אותי</label>
        </div>
        <button type="submit" class="btn btn-primary w-100">כניסה</button>
      </form>
    </div>
  </div>
</div>
