import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { UserService } from './core/services/user.service';
import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly router: Router,
    public readonly user: UserService
  ) {}
  ngOnInit() {
    this.user.loadUser();
    if (isPlatformBrowser(this.platformId)) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          (window as any).dataLayer.push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
          });
        }
      });
    }
  }
}
