import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/services/auth.guard';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'event',
  },
  {
    path: 'slika-event',
    loadChildren: () =>
      import('./slika-event/slika-event.module').then((m) => m.SlikaEventModule),
    canActivate: [AuthGuard],
  },  
  {
    path: 'event',
    loadChildren: () =>
      import('./event/event.module').then((m) => m.EventModule),
    canActivate: [AuthGuard],
  },
  
  {
    path: 'gift',
    loadChildren: () =>
      import('./gift-giving/gift-giving.module').then(
        (m) => m.GiftGivingModule
      ),
  },
  {
    path: ':id',
    loadChildren: () =>
      import('./arrival-confirmation/arrival-confirmation.module').then(
        (m) => m.ArrivalConfirmationModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
