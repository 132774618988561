import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { CommissionsGQL, CommissionsQuery } from 'src/generated/graphql';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CommissionsResolver implements Resolve<CommissionsQuery['commissions']> {
  constructor(private readonly commissionsGQL: CommissionsGQL) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.commissionsGQL.fetch()
    .pipe(map(({ data }) => {
        //console.log("Commissions data:", data);
        return data.commissions;
      }));
  }
}
