<div 
  class="
    d-flex
    justify-content-between
    align-items-center
  "
  style="
    border-radius: 35px;
    height: 44px;
    display: inline-block;
    width: 125px;
    border: 2px solid #d7dbe8;
  ">

  <button
    type="button"
    style="width: 35px; height: 35px; margin-right: 2px; line-height: 0;"
    class="btn btn-primary rounded-circle"
    (click)="onClickChangeCount(value - 1)">
    -
  </button>
  
  <span style="font-size: 20px">{{ value }}</span>

  <button
  type="button"
  style="width: 35px; height: 35px; margin-left: 2px; line-height: 0;"
  class="btn btn-primary rounded-circle"
  (click)="onClickChangeCount(value + 1)">
    +
  </button>
</div>