import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from './services/user.service';
import { AuthGuard } from './services/auth.guard';

@NgModule({
  providers: [UserService, AuthGuard],
  imports: [CommonModule],
})
export class CoreModule {}
