import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import {
  BrowserModule,
  BrowserTransferStateModule,
} from '@angular/platform-browser';
import { SignaturePadModule } from 'angular2-signaturepad';
import { SlikaEventModule } from './slika-event/slika-event.module';
import {ReactiveFormsModule} from "@angular/forms";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { CoreModule } from './core/core.module';
import { AuthModule } from './auth/auth.module';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import localeHe from '@angular/common/locales/he';
import { registerLocaleData } from '@angular/common';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
registerLocaleData(localeHe);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    LayoutModule,
    CoreModule,
    AuthModule,
    GraphQLModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserTransferStateModule,
    NoopAnimationsModule,
    ReactiveFormsModule,
    SignaturePadModule,
    SlikaEventModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'he' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'ILS' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
