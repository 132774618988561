import { Component, Inject, OnInit, Input, Output, EventEmitter, HostListener, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  SlikaEventsQuery,
  UpdateSlikaEventGQL,
  GuestListGQL,
  WhoAmIQuery,
  AttApprovedFilesGQL,
  UpdateFilesInDbGQL,
  GenerateUploadUrlsGQL,
  UpdateCgdCompleteGQL
  
} from '../../../generated/graphql';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  ValidationErrors,
  AbstractControl
} from '@angular/forms';
import moment from 'moment';
import Swal from 'sweetalert2';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import {saveAs} from 'file-saver';
import { Observable, SubscriptionLike, forkJoin, throwError } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { catchError, tap } from 'rxjs/operators';

interface IUploadDocumentsInput {
  eventId: string;
  requiredFilesOrder: string[];
  accountHolder_Id_fileName?: string;
  accountHolder_Id_fileSize?: number;
  accountHolder_bioIdBack_fileName?: string;
  accountHolder_bioIdBack_fileSize?: number;
  bankStatement_fileName?: string;
  bankStatement_fileSize?: number;
  accountBeneficiaryStatement_fileName?: string;
  accountBeneficiaryStatement_fileSize?: number;
  accountHolder_digitalSignature_fileName?: string;
  accountHolder_digitalSignature_fileSize?: number;
  
}
@Component({
  selector: 'app-edit-slika-event-dialog',
  templateUrl: './edit-slika-event-dialog.component.html',
  styleUrls: ['./edit-slika-event-dialog.component.scss']
})
export class EditSlikaEventDialogComponent implements OnInit {
  // @Input() toggleValue = false;
  // @Output() toggleValueChange = new EventEmitter<boolean>();  
  //@Input() user!: WhoAmIQuery['whoAmI'];
  @ViewChild('fileUpload', {static: false}) fileUpload?: ElementRef<HTMLInputElement>;


  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (this.showLightbox) {
      this.showLightbox = false;
    } else {
      this.matDialogRef.close();
    }
  }
  
  uploadedFiles: { [key: string]: {file: File, error?: string} | null } = {
    id1_file: null,
    id2_file: null,
    account_file: null,
    nehene_file: null,
    signature_file: null,
  };  
  requiredFiles : string[] = [];
  showLightbox: boolean = false;
  currentImage: string = '';
  currentImageSafe: SafeResourceUrl = '';

  updateSlikaEventForm!: FormGroup;
  hours: string[] = [];
  minutes: string[] = [];
  isSubmitted = false;
  slikaCanEdit = false;
  adminCanEdit = false;
  attApproveFilesButtonEnabled = false;
  attApproveFilesButtonText = 'אישור';
  isSubmitEnabled = false;
  supplierNumSubscription: SubscriptionLike | undefined; 
  referenceNumSubscription: SubscriptionLike | undefined;

  private initialSupplierNumEmpty: boolean = false;
  private initialReferenceNumEmpty: boolean = false;
    
  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: { slikaEvent: SlikaEventsQuery['slikaEvents'][0], user: WhoAmIQuery['whoAmI'] },
    private readonly updateSlikaEventGQL: UpdateSlikaEventGQL,
    //private readonly updateUserGQL: UpdateUserGQL,
    private readonly matDialogRef: MatDialogRef<EditSlikaEventDialogComponent>,
    private readonly fb: FormBuilder,
    private http: HttpClient,
    private readonly guestListGQL: GuestListGQL,
    private sanitizer: DomSanitizer,
    private readonly attApprovedFilesGQL: AttApprovedFilesGQL,
    private readonly updateFilesInDBGQL: UpdateFilesInDbGQL,
    private readonly generateUploadUrlsGQL: GenerateUploadUrlsGQL,
    private readonly updateCgdCompleteGQL: UpdateCgdCompleteGQL,
  ) { }

  ngOnInit(): void {
    this.matDialogRef.disableClose = true; // disable clicking outside the dialog
    // for(let i = 0; i <= 23; i++) {
    //   for(let j = 0; j < 60; j++) {
    //     let hour = i < 10 ? '0' + i : '' + i;
    //     let minutes = j <10 ? '0' + j : '' + j;
        
    //     this.referenceNumTimes.push(hour + ':' + minutes);
    //   }
    // }
    for(let i = 0; i < 24; i++) {
      let hour = i < 10 ? '0' + i : '' + i;
      this.hours.push(hour);
    }
  
    for(let j = 0; j < 60; j++) {
      let minute = j < 10 ? '0' + j : '' + j;
      this.minutes.push(minute);
    }
    
    let referenceNumDate: Date | null = this.data.slikaEvent.referenceNumDate ? new Date(this.data.slikaEvent.referenceNumDate) : null;
    let referenceNumHour: string | null = referenceNumDate ? referenceNumDate.getHours().toString().padStart(2, '0') : null;
    let referenceNumMinute: string | null = referenceNumDate ? referenceNumDate.getMinutes().toString().padStart(2, '0') : null;


    const supplierNumValue = this.data.slikaEvent.supplierNum;
    const referenceNumValue = this.data.slikaEvent.referenceNum;
    


    this.initialSupplierNumEmpty = !supplierNumValue;
    this.initialReferenceNumEmpty = !this.data.slikaEvent.referenceNum;

    if (!this.initialSupplierNumEmpty || !this.initialReferenceNumEmpty){
    this.slikaCanEdit = true;
    }

    let supplierNumDisabled = false;
    
    // if (supplierNumValue || !this.data.slikaEvent.cgdComplete) { 
    // changing cgdComplete to attApproveFilesButtonEnabled because only after admin approves files:
    
    
    if (!this.data.slikaEvent.attApprovedFiles){
      supplierNumDisabled = true;
  } else {
    if (supplierNumValue) {
      supplierNumDisabled = true;
    }
  }

    this.updateSlikaEventForm = this.fb.group({
      eventId: [this.data.slikaEvent.eventId],
      // supplierNum: [{ value: supplierNumValue, disabled: supplierNumValue ? true : false }],
      supplierNum: [{ value: supplierNumValue, disabled: supplierNumDisabled }, [Validators.pattern(/^\d{2,}$/)]],

      referenceNum: [{value: referenceNumValue, disabled: supplierNumValue && !referenceNumValue ? false : true}, [Validators.pattern(/^\d{2,}$/)]],
      accountHolderName: [this.data.slikaEvent.accountHolderName],
      accountHolderId: [this.data.slikaEvent.accountHolderId],
      accountHolderMobile: [this.data.slikaEvent.accountHolderMobile],
      transactionDate: [moment(this.data.slikaEvent.transactionDate).toDate(), Validators.required],
      //amountGross: [this.data.slikaEvent.amountGross+' $'],
      // slikaCommission: ['', Validators.required],
      // attCommission: ['', Validators.required],
      // amountNet: ['', Validators.required],
      transactionStatus: [this.data.slikaEvent.transactionStatus, Validators.required],
      accountNum: [this.data.slikaEvent.accountNum],
      branchNum: [this.data.slikaEvent.branchNum],
      bankNum: [this.data.slikaEvent.bankNum],
      eventType: [this.data.slikaEvent.eventType, Validators.required],
      eventDate: [moment(this.data.slikaEvent.eventDate).toDate(), Validators.required],
      name: [this.data.slikaEvent.name, Validators.required],
      referenceNumDate:[{value: this.data.slikaEvent.referenceNumDate ? moment(this.data.slikaEvent.referenceNumDate).toDate() : null, disabled:true}],
      referenceNumHour: [{value: referenceNumHour, disabled: true}],
      referenceNumMinute: [{value: referenceNumMinute, disabled: true}],
      //business_event_type: [this.slikaEvent.business_event_type],
    });

    if (!supplierNumValue || supplierNumValue == ''){
      this.watchSupplierNumChanges();
    }

    if (!referenceNumValue || referenceNumValue == ''){
      this.watchReferenceNumChanges();
    }
  
    //this.loadGuestListData(this.data.slikaEvent.eventId);
    //this.loadSlikaEventTotals();
    this.manageAttApproveFilesButton();
  }

  manageAttApproveFilesButton(){
    
    if (this.data.slikaEvent.cgdComplete){
      //console.log('cgd is complete');
      if (this.data.slikaEvent.attApprovedFiles){
        this.attApproveFilesButtonEnabled = false;
        this.attApproveFilesButtonText = 'אושרו';
      }
      else{
        this.attApproveFilesButtonEnabled = true;
        this.attApproveFilesButtonText = 'אישור';
      }
    } else {
      //console.log('cgd is NOT complete');
      this.attApproveFilesButtonEnabled = false;
        this.attApproveFilesButtonText = 'לא זמין';
    }
    // the above code replaces this code:
    // if (this.data.slikaEvent.attApprovedFiles === false)
    // {
    // if (this.data.slikaEvent.idType == 'bio'){
    //  if (this.data.slikaEvent.accountHolder_Id_fileUrl && this.data.slikaEvent.accountHolder_bioIdBack_fileUrl
    //   && this.data.slikaEvent.bankStatement_fileUrl && this.data.slikaEvent.accountBeneficiaryStatement_fileUrl
    //   && this.data.slikaEvent.accountHolder_digitalSignature_fileUrl){
    //       this.attApproveFilesButtonEnabled = true;
    //   } else {this.attApproveFilesButtonEnabled = false;
    //     this.attApproveFilesButtonText = 'אושרו';}
    // } else if (this.data.slikaEvent.idType == 'regular'){
    //   if (this.data.slikaEvent.accountHolder_Id_fileUrl && this.data.slikaEvent.bankStatement_fileUrl 
    //     && this.data.slikaEvent.accountBeneficiaryStatement_fileUrl
    //     && this.data.slikaEvent.accountHolder_digitalSignature_fileUrl){
    //         this.attApproveFilesButtonEnabled = true;
    //     } else {this.attApproveFilesButtonEnabled = false;
    //       this.attApproveFilesButtonText = 'אושרו';}
    // } else {this.attApproveFilesButtonEnabled = false;
    //   this.attApproveFilesButtonText = 'אושרו';}
    // } 
    // else {this.attApproveFilesButtonEnabled = false;
    //   this.attApproveFilesButtonText = 'אושרו';}
    
      //console.log('this.attApproveFilesButtonEnabled: ',this.attApproveFilesButtonEnabled);
    
  }

  openLightbox(imageUrl: string): void {
    this.currentImage = imageUrl;
    this.currentImageSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.currentImage);
    this.showLightbox = true;
  }

  closeLightbox(): void {
    this.showLightbox = false;
    this.currentImage = '';
    this.currentImageSafe = '';
  }

  onClickAttApproveFiles(){
    this.attApprovedFilesGQL
            .mutate({
              eventId: this.data.slikaEvent.eventId
            })
            .subscribe((result) => {
              this.data.slikaEvent.attApprovedFiles = result.data?.attApprovedFiles.attApprovedFiles;
              //console.log('result',result);
              this.manageAttApproveFilesButton();
              // check whether to open supplierNum field or not.
              if (!this.attApproveFilesButtonEnabled){
                this.updateSlikaEventForm.get('supplierNum')?.enable();
                this.updateSlikaEventForm.get('supplierNum')?.setValidators([Validators.pattern(/^\d{2,}$/)]);
                this.updateSlikaEventForm.get('supplierNum')?.updateValueAndValidity();
                
              }
        },
        (error) => {
          // Handle errors for the updateSlikaEvent mutation if needed
          console.error('Error updating attApprovedFiles in slika-event table:', error);
        }
            );
  }

  watchSupplierNumChanges(){
    this.supplierNumSubscription = this.updateSlikaEventForm.get('supplierNum')?.valueChanges.subscribe(val => {
      if (!this.data.slikaEvent.referenceNum || this.data.slikaEvent.referenceNum == ''){
        if (val) {
          this.updateSlikaEventForm.get('referenceNum')?.enable();
          this.isSubmitEnabled = true;
        } else {
          this.updateSlikaEventForm.get('referenceNum')?.disable();
          this.updateSlikaEventForm.get('referenceNumDate')?.disable();
          this.updateSlikaEventForm.get('referenceNumHour')?.disable();
          this.updateSlikaEventForm.get('referenceNumMinute')?.disable();
          this.isSubmitEnabled = false;
        }
      } else {
        this.updateSlikaEventForm.get('referenceNum')?.enable();
        this.updateSlikaEventForm.get('referenceNumDate')?.enable();
        this.updateSlikaEventForm.get('referenceNumHour')?.enable();
        this.updateSlikaEventForm.get('referenceNumMinute')?.enable();
        this.isSubmitEnabled = true;
      }
      
    });
  }

  watchReferenceNumChanges(){
    this.referenceNumSubscription = this.updateSlikaEventForm.get('referenceNum')?.valueChanges.subscribe(val => {
      if (val) {
        this.updateSlikaEventForm.get('referenceNumDate')?.enable();
        this.updateSlikaEventForm.get('referenceNumHour')?.enable();
        this.updateSlikaEventForm.get('referenceNumMinute')?.enable();
        this.isSubmitEnabled = true;
        this.updateSlikaEventForm.get('referenceNumDate')?.setValidators([Validators.required]);
        this.updateSlikaEventForm.get('referenceNumHour')?.setValidators([Validators.required]);
        this.updateSlikaEventForm.get('referenceNumMinute')?.setValidators([Validators.required]);
      } else {
        this.updateSlikaEventForm.get('referenceNumDate')?.disable();
        this.updateSlikaEventForm.get('referenceNumHour')?.disable();
        this.updateSlikaEventForm.get('referenceNumMinute')?.disable();
        this.isSubmitEnabled = false;
        this.updateSlikaEventForm.get('referenceNumDate')?.clearValidators();
        this.updateSlikaEventForm.get('referenceNumHour')?.clearValidators();
        this.updateSlikaEventForm.get('referenceNumMinute')?.clearValidators();
    
        this.updateSlikaEventForm.get('referenceNumDate')?.reset();
        this.updateSlikaEventForm.get('referenceNumHour')?.reset();
        this.updateSlikaEventForm.get('referenceNumMinute')?.reset();        
      }

      this.updateSlikaEventForm.get('referenceNumDate')?.updateValueAndValidity();
      this.updateSlikaEventForm.get('referenceNumHour')?.updateValueAndValidity();
      this.updateSlikaEventForm.get('referenceNumMinute')?.updateValueAndValidity();
    });    
  }
  
  // async loadGuestListData(eventId: string): Promise<void> {
  //   try {
  //     const { data } = await this.guestListGQL.fetch({ eventId }).toPromise();
  //     const guestList = data.guestList;
  //     // Calculate the sum of giftAmount and giftCommission
  //     const giftAmountSum = guestList.reduce((sum, guest) => sum + guest.giftAmount, 0);
  //     const giftCommissionSum = guestList.reduce((sum, guest) => sum + guest.giftCommission, 0);
  
  //     // Update the form values
  //     this.updateSlikaEventForm.patchValue({
  //       amountGross: giftAmountSum,
  //       //commissionsTotal: giftCommissionSum, //needed ?
  //       slikaCommission: 0,
  //       attCommission: 0,
  //       amountNet: giftAmountSum - giftCommissionSum
  //     });
  //   } catch (error) {
  //     console.error('Error loading guest list data:', error);
  //   }
  // }

  // loadSlikaEventTotals(){
  //   this.updateSlikaEventForm.patchValue({
  //     amountGross: this.data.slikaEvent.amountGross,
  //     //commissionsTotal: giftCommissionSum, //needed ?
  //     slikaCommission: this.data.slikaEvent.slikaCommission,
  //     attCommission: this.data.slikaEvent.attCommission,
  //     amountNet: this.data.slikaEvent.amountNet
  //   });
  // }


  // get business_event_type() {
  //   return this.updateSlikaEventForm.get('business_event_type') as FormControl;
  // }
  get referenceNumDate() {
    return this.updateSlikaEventForm.get('referenceNumDate') as FormControl;
  }
  get referenceNumHour() {
    return this.updateSlikaEventForm.get('referenceNumHour') as FormControl;
  }
  get referenceNumMinute() {
    return this.updateSlikaEventForm.get('referenceNumMinute') as FormControl;
  }
  get supplierNum() {
    return this.updateSlikaEventForm.get('supplierNum') as FormControl;
  }
  get referenceNum() {
    return this.updateSlikaEventForm.get('referenceNum') as FormControl;
  }
  get accountHolderName() {
    return this.updateSlikaEventForm.get('accountHolderName') as FormControl;
  }
  get accountHolderId() {
    return this.updateSlikaEventForm.get('accountHolderId') as FormControl;
  }
  get accountHolderMobile() {
    return this.updateSlikaEventForm.get('accountHolderMobile') as FormControl;
  }
  get transactionDate() {
    return this.updateSlikaEventForm.get('transactionDate') as FormControl;
  }
  // get amountGross() {
  //   return this.updateSlikaEventForm.get('amountGross') as FormControl;
  // }
  // get slikaCommission() {
  //   return this.updateSlikaEventForm.get('slikaCommission') as FormControl;
  // }

  // get attCommission() {
  //   return this.updateSlikaEventForm.get('attCommission') as FormControl;
  // }

  // get amountNet() {
  //   return this.updateSlikaEventForm.get('amountNet') as FormControl;
  // }
  get transactionStatus() {
    return this.updateSlikaEventForm.get('transactionStatus') as FormControl;
  }
  get accountNum() {
    return this.updateSlikaEventForm.get('accountNum') as FormControl;
  }
  get branchNum() {
    return this.updateSlikaEventForm.get('branchNum') as FormControl;
  }
  get bankNum() {
    return this.updateSlikaEventForm.get('bankNum') as FormControl;
  }
  get eventType() {
    return this.updateSlikaEventForm.get('eventType') as FormControl;
  }
  get eventDate() {
    return this.updateSlikaEventForm.get('eventDate') as FormControl;
  }
  get name() {
    return this.updateSlikaEventForm.get('name') as FormControl;
  }

  // get isSubmitEnabled(): boolean {
  //   return ((this.initialSupplierNumEmpty && !!this.supplierNum.value) || 
  //           (this.initialReferenceNumEmpty && !!this.referenceNum.value));
  // }
  
  // dateValidator(control: AbstractControl): ValidationErrors | null {
  //   const value = control.value;
  //   // console.log('Value:', value);  // To check the value coming in
  //   const validationResponse = (value === null || value === '' || isNaN(new Date(value).getTime())) ? { 'invalidDate': true } : null;
  //   // console.log('Validation Response:', validationResponse); // To check what the validator is returning
  //   return validationResponse;
  // }
  
  downloadFile(url: string, filename: string) {
    this.http.get(url, { responseType: 'blob' }).subscribe(blob => {
      saveAs(blob, filename);
    });
  }
  

  onSelectedFileToUpload(fileInput: HTMLInputElement, key: string) {
    if (!fileInput.files || fileInput.files.length == 0) {
      return;
    }
    const file = fileInput.files[0];
    this.uploadedFiles[key] = {
        file: file,
        error: this.isInvalidFileType(file) ? 'invalid' : undefined,
    };

        if (this.uploadedFiles[key]?.error){
          this.createToastAlert().fire({
              icon: 'error', 
              title: 'הקובץ ' + this.uploadedFiles[key]?.file.name + ' אינו נתמך.'
            }).then(() => {
              this.uploadedFiles[key] = null;
            });
        } else
        if (this.uploadedFiles[key]!.file.size > 5000000) {
          this.createToastAlert().fire({
              icon: 'error', 
              title: 'הקובץ ' + this.uploadedFiles[key]?.file.name + ' גדול מידי.',
              text: 'גודל מקסימלי: 5MB'
            }).then(() => {
              this.uploadedFiles[key] = null;
            });
        }        

        if (!this.uploadedFiles[key]?.error && this.uploadedFiles[key]!.file.size <= 5000000){
        // Pass the file to the mutation here
        
        this.requiredFiles = [key];
        console.log('key: ',key);
        let uploadFileInput: IUploadDocumentsInput = {
          eventId: this.data.slikaEvent.eventId,
          requiredFilesOrder: this.requiredFiles,
          accountHolder_Id_fileName: this.uploadedFiles['id1_file']?.file.name,
          accountHolder_Id_fileSize: this.uploadedFiles['id1_file']?.file.size,
          accountHolder_bioIdBack_fileName: this.uploadedFiles['id2_file']?.file.name,
          accountHolder_bioIdBack_fileSize: this.uploadedFiles['id2_file']?.file.size,
          bankStatement_fileName: this.uploadedFiles['account_file']?.file.name,
          bankStatement_fileSize: this.uploadedFiles['account_file']?.file.size,
          accountBeneficiaryStatement_fileName: this.uploadedFiles['nehene_file']?.file.name,
          accountBeneficiaryStatement_fileSize: this.uploadedFiles['nehene_file']?.file.size,
          accountHolder_digitalSignature_fileName: this.uploadedFiles['signature_file']?.file.name,
          accountHolder_digitalSignature_fileSize: this.uploadedFiles['signature_file']?.file.size,
        };

        Swal.fire({
          title: 'מעלה קובץ...',
          html: '<div class="spinner-border text-primary" role="status"></div>', // Use Bootstrap spinner
          showConfirmButton: false,
          allowOutsideClick: false,
        });        
        
        let uploadObservable: Observable<HttpEvent<any>>[] = [];

  this.generateUploadUrlsGQL.mutate({ uploadDocumentsInput: uploadFileInput }).subscribe({ 
    next: ({data }) => {

    if (data && Array.isArray(data.generateUploadUrls)) {
      data.generateUploadUrls.forEach((url, index) => {
        //const fileData = this.uploadedFiles[Object.keys(this.uploadedFiles)[index]];
        // const fileKey = Object.keys(this.uploadedFiles)[index];
        const fileKey = this.requiredFiles[index]; // Use the key from the requiredFiles array

        const fileData = this.uploadedFiles[fileKey];

        if (fileData) {
          console.log('put: url: ',url, 'file: ',fileData.file.name);

          //this.percentage = 0; // Initialize percentage for each file
          //this.uploadPercentages[fileKey] = 0; // Initialize percentage for each file

          const req = new HttpRequest('PUT', url, fileData.file, {
            reportProgress: true,
          });

          uploadObservable.push(
            this.http.request(req).pipe(
              tap((event: HttpEvent<any>) => {
                // this if statement is for handling the progress of the upload
                // if (event.type === HttpEventType.UploadProgress) {
                //   this.uploadPercentages[fileKey] = event.total
                //     ? Math.round((100 * event.loaded) / event.total)
                //     : 0;
                // }
              }),
              catchError((error) => {
                console.error('There was an error in uploadObservables.push! (',key,')', error);
                // this code means to delete the mutation that was made because the files failed to upload
                // but if a previous existing file is documented in the db it will be deleted of course. 
                // that's why this block is commented out...
                
                // this.removeDocsFromSlikaEventDbGQL.mutate({ uploadDocumentsInput }).subscribe({
                //   next: (response) => {
                //     // Handle the response of your mutation here
                //     console.log('files were successfully deleted from DB. response: ', response);
                //   },
                //   error: (error) => {
                //     // Handle the error of your mutation here
                //     console.log('files were not  deleted from DB. error: ', error);
                //   },
                // });
                return throwError(error);
              })
            ) as Observable<HttpEvent<{}>>
          );
        }
      });

      forkJoin(uploadObservable).subscribe({
        next: () => {
          this.updateFilesInDBGQL.mutate({ uploadDocumentsInput: uploadFileInput }).subscribe({
            next: (response) => {
              // Handle the response of your mutation here
              Swal.close();

              //console.log('files were successfully uploaded to DB . response: ', response);
              Swal.fire({
                icon: 'success',
                titleText: 'הקובץ הועלה בהצלחה',
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                confirmButtonText: 'אישור',
              }).then(() => {
                Object.assign(this.data.slikaEvent, response.data?.updateFilesInDB);
                //check if all necessary files are present
                this.checkCgdComplete();
                //this.manageAttApproveFilesButton();
              });
            },
            error: (error) => {
              // Handle the error of your mutation here
              console.log(key,' file was not loaded to DB. error: ', error);
              Swal.close();
            },
          });
         
          
        },
        error: (error) => {
          console.error('There was an error! ',key,' file was not uploaded', error);
          Swal.close();
        },
      });
    }
  },
  error: (error) => {
    // Handle the error of generating URLs here
    console.error('There was an error generating upload URL for ',key,'. error: ', error);
    Swal.close();
  }
});

        } 
        else {
          // Show an error message here
          console.log('Error: File type or size are invalid.');
        }
      
  }

checkCgdComplete(){
  if (this.data.slikaEvent.idType === 'regular'){
    console.log('regular id');
    if (this.data.slikaEvent.accountHolder_Id_fileUrl && this.data.slikaEvent.accountBeneficiaryStatement_fileUrl &&
      this.data.slikaEvent.bankStatement_fileUrl && this.data.slikaEvent.accountHolder_digitalSignature_fileUrl) {
        //console.log('cgdComplete true');
        this.updateCgdCompleteGQL.mutate({eventId: this.data.slikaEvent.eventId, cgdComplete: true  }).subscribe({
          next: ({ data }) => {
            //console.log('cgdComplete mutation response: ', data);
            Object.assign(this.data.slikaEvent, data?.updateCgdComplete);
            //console.log('data: ', data);
            //console.log('this.data.slikaEvent.cgdComplete: ', this.data.slikaEvent.cgdComplete);
            if (this.data.slikaEvent.attApprovedFiles){
              this.attApproveFilesButtonEnabled = false;
              this.attApproveFilesButtonText = 'אושרו';
            }
            else {
              this.attApproveFilesButtonEnabled = true;
              this.attApproveFilesButtonText = 'אישור';
            }
          },
          error: (error) => {
            // Handle the error here
            console.error('regular There was an error updating cgdComplete', error);
          }
  
        });  
      }
  }
  else // if idType is 'bio'
  {
    console.log('bio id');
    if (this.data.slikaEvent.accountHolder_Id_fileUrl && this.data.slikaEvent.accountBeneficiaryStatement_fileUrl &&
      this.data.slikaEvent.bankStatement_fileUrl && this.data.slikaEvent.accountHolder_digitalSignature_fileUrl &&
      this.data.slikaEvent.accountHolder_bioIdBack_fileUrl) {
        //console.log('bio cgdComplete true');
        this.updateCgdCompleteGQL.mutate({eventId: this.data.slikaEvent.eventId, cgdComplete: true  }).subscribe({
          next: ({ data }) => {
            //console.log('cgdComplete mutation response: ', data);
            Object.assign(this.data.slikaEvent, data?.updateCgdComplete);
            // console.log('bio data: ', data);
            // console.log('bio this.data.slikaEvent.cgdComplete: ', this.data.slikaEvent.cgdComplete);
            if (this.data.slikaEvent.attApprovedFiles){
              this.attApproveFilesButtonEnabled = false;
              this.attApproveFilesButtonText = 'אושרו';
            }
            else {
              this.attApproveFilesButtonEnabled = true;
              this.attApproveFilesButtonText = 'אישור';
            }
  
          },
          error: (error) => {
            // Handle the error here
            console.error('bio There was an error updating cgdComplete', error);
          }
  
        });  
      }
  }
}

  private createToastAlert() {
    return Swal.mixin({
      toast: true,
      position: 'center',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });
  }
  
  private isInvalidFileType(file: File) {
    const fileExtension = file.name
      .split('.')
      .pop()
      ?.trim()
      .toLocaleLowerCase();
    return fileExtension == null
      ? true
      : !['png', 'jpg', 'jpeg', 'pdf'].includes(fileExtension);
  }
  // async onToggleValueChange(value: boolean) {
  //   //this.toggle = value;
  //   this.toggleValue = !this.toggleValue;

  //   // if (!this.toggle) {
  //   // }
  // }
  
  async onSubmitUpdate() {
    this.isSubmitted = true;
    //console.log('referenceNumDate: ', this.updateSlikaEventForm.get('referenceNumDate')?.value);
    //console.log('errors: ',this.updateSlikaEventForm.get('referenceNumDate')?.errors);

    if (this.updateSlikaEventForm.invalid) {
      console.log('updateSlikaEventForm invalid');
      
      return;
    }
    
    //const data = this.updateSlikaEventForm.value;
    const data = this.updateSlikaEventForm.getRawValue();
    
    
    this.updateSlikaEventGQL.mutate({ updateSlikaEventInput: data }).subscribe({
      next: ({ data: slikaEvent }) => {
        //this.referenceNumSubscription!.unsubscribe();

        this.matDialogRef.close(slikaEvent!.updateSlikaEvent);
      },
    });
  }

  async onClickSlikaUpdate(){

  }

  async onClickAdminUpdate(){

  }

  onClickEnableEdit(){
    this.slikaCanEdit = false;
    switch(this.data.user.isAdmin){
      case true:
        this.initialReferenceNumEmpty = true;  
        this.initialSupplierNumEmpty = true;
        this.updateSlikaEventForm.get('referenceNum')?.enable();
        this.updateSlikaEventForm.get('referenceNumDate')?.enable();
        this.updateSlikaEventForm.get('referenceNumHour')?.enable();
        this.updateSlikaEventForm.get('referenceNumMinute')?.enable();
        
        this.updateSlikaEventForm.get('supplierNum')?.enable();
        
        this.watchReferenceNumChanges();
        this.watchSupplierNumChanges();
        
        //console.log('referenceNum: ', this.updateSlikaEventForm.get('referenceNum')?.value);
        
        //console.log('isSubmitEnabled: ', this.updateSlikaEventForm.get('isSubmitEnabled')?.value);
        
        break;
      case false:
        break;
      default:
        console.log('default. this.data.user.isAdmin: ', this.data.user.isAdmin);
        break;
    
    }
  }
}
