<form [formGroup]="updateSlikaEventForm" (ngSubmit)="onSubmitUpdate()">
    <div class="container-fluid ">
      <div class="row">
        <div class="col-12">
          <h4>עריכת פרטי חבילת מתנות באשראי - אירוע {{ data.slikaEvent.eventId }}</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <label class="w-100 mb-2 fs-9">
              סוג האירוע
              <input readonly formControlName="eventType" class="form-control "  />
          </label>
      </div>
  
        <!-- <div class="col-6">
          <label class="w-100">
            סוג אירוע עסקי
                <input formControlName="business_event_type" class="form-control" />
              </label>
        </div>         -->
        <div class="col-4">
          <label class="w-100 fs-9">
            אירוע של
            <input readonly formControlName="name"  class="form-control" />
          </label>
        </div>

      <div class="col-4">
        <label class="w-100 mb-2 fs-9">
          תאריך האירוע
          <input readonly formControlName="eventDate" class="form-control"  [matDatepicker]="eventDatePicker" />
        </label>
        <mat-datepicker #eventDatePicker></mat-datepicker>
        
        
      </div>
      <!-- <div class="col-3">
        
          <label class="w-100 mb-2 fs-9">
            תאריך העברה
            <input readonly formControlName="transactionDate" class="form-control" [matDatepicker]="transactionDatePicker" />
          </label>
          <mat-datepicker #transactionDatePicker></mat-datepicker>
        </div>         -->
      </div>      
      <div class="row">
        <div class="col-12 flex-fill line my-2"></div>
      </div>
      <div class="row">
        <div class="col-4">
          <label class="w-100 mb-2 fs-9">
            שם בעל החשבון
            <input readonly formControlName="accountHolderName" class="form-control" />
          </label>
        </div>
        <div class="col-4">
            <label class="w-100 mb-2 fs-9">
              ת.ז. בעל החשבון
              <input readonly formControlName="accountHolderId" class="form-control" />
            </label>
          </div>
        <div class="col-4">
            <label class="w-100 mb-2 fs-9">
                נייד
                <input readonly formControlName="accountHolderMobile" class="form-control" />
            </label>
        </div>       
      </div>      
      
      <div class="row">
        <div class="col-4">
          <label class="w-100 mb-2 fs-9">
              מס' חשבון
              <input readonly formControlName="accountNum" class="form-control"  />
          </label>
      </div>
      <div class="col-4">
          <label class="w-100 mb-2 fs-9">
              מס' סניף
              <input readonly formControlName="branchNum" class="form-control"  />
          </label>
      </div>
      <div class="col-4">
          <label class="w-100 fs-9">
              מספר בנק
              <input readonly formControlName="bankNum" class="form-control"  />
          </label>
      </div>                
      </div>
      
      <div class="row">
        <div class="col-12 flex-fill line my-2"></div>
      </div>
      <div class="row">
        <div class="col" style="border-left: 1px solid; border-color: #cccfd8;">
          <label class="w-100 mb-0 fs-9">
            תעודת זהות
          </label>
        <div class="d-flex align-items-center">
          <div class="lightbox" *ngIf="showLightbox && data.slikaEvent.accountHolder_Id_fileUrl" (click)="closeLightbox()">
            <object [data]="currentImageSafe" *ngIf="currentImage.endsWith('.pdf')" type="application/pdf" class="lightbox-object">
                <embed [src]="currentImageSafe" type="application/pdf" />
            </object>
            <img [src]="currentImage" *ngIf="!currentImage.endsWith('.pdf')" class="lightbox-image">
        </div>

        <div class="d-flex flex-grow-1 align-items-center justify-content-between">
          <a *ngIf="data.slikaEvent.accountHolder_Id_fileUrl; else fileNotAvailable"
              (click)="downloadFile(data.slikaEvent.accountHolder_Id_fileUrl!, data.slikaEvent.accountHolder_Id_fileName!)"
              class="me-2"
              style="cursor: pointer;" >
              <img src="../../../assets/icons/file-download-02.svg" alt="download" title="הורדה" style="opacity: 0.6;">
              
        </a>
            <ng-template #fileNotAvailable>
              <button type="button" 
                      class="btn btn-primary px-2 py-1 w-100 me-2 d-flex align-items-center justify-content-center"
                      style="max-height: 25px;"
                      disabled
                      >לא זמין
              </button>
          </ng-template>
          
            <a *ngIf="data.slikaEvent.accountHolder_Id_fileUrl"
                (click)="openLightbox(data.slikaEvent.accountHolder_Id_fileUrl)"
                class="me-2"
                style="cursor: pointer;" >
                <img src="../../../assets/icons/eye.svg" alt="צפייה" title="צפייה"  style="opacity: 0.6;">
            </a>
            <a *ngIf="data.user.isAdmin && !(data.user.id === '3')"
            (click)="id1_fileUpload.click()"
            
            style="cursor: pointer;" >
            <img src="../../../assets/icons/upload-cloud-01.svg" alt="upload" title="העלאת קובץ" style="opacity: 0.6;">
            <input type="file" #id1_fileUpload style="display: none" (change)="onSelectedFileToUpload(id1_fileUpload,'id1_file')"
            accept=".jpg, .png, .pdf, .jpeg">  
          </a>
            

  
        </div>
        
        
      </div>

        </div>
        <div *ngIf="data.slikaEvent.idType == 'bio'" class="col"  style="border-left: 1px solid; border-color: #cccfd8;">
          <label class="w-100 mb-0 fs-9">
            גב ת.ז.
          </label>
          <div class="d-flex align-items-center">
            
  
          <div class="d-flex flex-grow-1 align-items-center justify-content-between">
            <a *ngIf="data.slikaEvent.accountHolder_bioIdBack_fileUrl; else fileNotAvailable" 
              (click)="downloadFile(data.slikaEvent.accountHolder_bioIdBack_fileUrl!, data.slikaEvent.accountHolder_bioIdBack_fileName!)"
              class="me-2"
              style="cursor: pointer;" >
              <img src="../../../assets/icons/file-download-02.svg" alt="download" title="הורדה"  style="opacity: 0.6;">
          </a>

          <a *ngIf="data.slikaEvent.accountHolder_bioIdBack_fileUrl"
                (click)="openLightbox(data.slikaEvent.accountHolder_bioIdBack_fileUrl)"
                class="me-2"
                style="cursor: pointer;" >
                <img src="../../../assets/icons/eye.svg" alt="צפייה" title="צפייה"  style="opacity: 0.6;">
            </a>          
            <a *ngIf="data.user.isAdmin && !(data.user.id === '3')"
            (click)="id2_fileUpload.click()"
            
            style="cursor: pointer;" >
            <img src="../../../assets/icons/upload-cloud-01.svg" alt="upload" title="העלאת קובץ" style="opacity: 0.6;">
            <input type="file" #id2_fileUpload style="display: none" (change)="onSelectedFileToUpload(id2_fileUpload,'id2_file')"
            accept=".jpg, .png, .pdf, .jpeg">
            </a>  
            
        </div>  
      </div>            
          
        </div>
        <div class="col" style="border-left: 1px solid; border-color: #cccfd8;">
          <label class="w-100 mb-0 fs-9">
            א. ניהול חשבון
          </label>
          <div class="d-flex align-items-center">
            
  
          <div class="d-flex flex-grow-1 align-items-center justify-content-between">
            <a *ngIf="data.slikaEvent.bankStatement_fileUrl; else fileNotAvailable" 
              (click)="downloadFile(data.slikaEvent.bankStatement_fileUrl!, data.slikaEvent.bankStatement_fileName!)"
              class="me-2"
              style="cursor: pointer;" >
              <img src="../../../assets/icons/file-download-02.svg" alt="download" title="הורדה" style="opacity: 0.6;">
          </a>

          <a *ngIf="data.slikaEvent.bankStatement_fileUrl"
                (click)="openLightbox(data.slikaEvent.bankStatement_fileUrl)"
                class="me-2"
                style="cursor: pointer;" >
                <img src="../../../assets/icons/eye.svg" alt="צפייה" title="צפייה" style="opacity: 0.6;">
            </a>          
            <a *ngIf="data.user.isAdmin && !(data.user.id === '3')"
            (click)="account_fileUpload.click()"
            
            style="cursor: pointer;" >
            <img src="../../../assets/icons/upload-cloud-01.svg" alt="upload" title="העלאת קובץ" style="opacity: 0.6;">
            <input type="file" #account_fileUpload style="display: none" (change)="onSelectedFileToUpload(account_fileUpload,'account_file')"
            accept=".jpg, .png, .pdf, .jpeg">  
          </a>  
            
          </div>  
        </div>               
          
        </div>
        <div class="col" style="border-left: 1px solid; border-color: #cccfd8;">
          <label class="w-100 mb-0 fs-9">
            הצהרת נהנה
          </label>
          <div class="d-flex align-items-center">
            
  
          <div class="d-flex flex-grow-1 align-items-center justify-content-between">
            <a *ngIf="data.slikaEvent.accountBeneficiaryStatement_fileUrl; else fileNotAvailable" 
              (click)="downloadFile(data.slikaEvent.accountBeneficiaryStatement_fileUrl!, data.slikaEvent.accountBeneficiaryStatement_fileName!)"
              class="me-2"
              style="cursor: pointer;" >
              <img src="../../../assets/icons/file-download-02.svg" alt="download" title="הורדה" style="opacity: 0.6;">
          </a>

          <a *ngIf="data.slikaEvent.accountBeneficiaryStatement_fileUrl"
                (click)="openLightbox(data.slikaEvent.accountBeneficiaryStatement_fileUrl)"
                class="me-2"
                style="cursor: pointer;" >
                <img src="../../../assets/icons/eye.svg" alt="צפייה" title="צפייה" style="opacity: 0.6;">
            </a>          
            <a *ngIf="data.user.isAdmin && !(data.user.id === '3')"
            (click)="nehene_fileUpload.click()"
            
            style="cursor: pointer;" >
            <img src="../../../assets/icons/upload-cloud-01.svg" alt="upload" title="העלאת קובץ" style="opacity: 0.6;">
            <input type="file" #nehene_fileUpload style="display: none" (change)="onSelectedFileToUpload(nehene_fileUpload,'nehene_file')"
            accept=".jpg, .png, .pdf, .jpeg">
            </a>  
            
          </div>  
        </div>                       
          
        </div>   
        <div class="col" style="border-left: 1px solid; border-color: #cccfd8;">
          <label class="w-100 mb-0 fs-9">
            חתימה
          </label>
          <div class="d-flex align-items-center">
          <div class="d-flex flex-grow-1 align-items-center justify-content-between">              
            <a *ngIf="data.slikaEvent.accountHolder_digitalSignature_fileUrl; else fileNotAvailable" 
              (click)="downloadFile(data.slikaEvent.accountHolder_digitalSignature_fileUrl!, data.slikaEvent.accountHolder_digitalSignature_fileName!)"
              class="me-2"
              style="cursor: pointer;" >
              <img src="../../../assets/icons/file-download-02.svg" alt="download" title="הורדה" style="opacity: 0.6;">
          </a>

          <a *ngIf="data.slikaEvent.accountHolder_digitalSignature_fileUrl"
          (click)="openLightbox(data.slikaEvent.accountHolder_digitalSignature_fileUrl)"
          class="me-2"
          style="cursor: pointer;" >
          <img src="../../../assets/icons/eye.svg" alt="צפייה" title="צפייה" style="opacity: 0.6;">
          </a>          
          
          <a *ngIf="data.user.isAdmin && !(data.user.id === '3')"
          (click)="signature_fileUpload.click()"
          
          style="cursor: pointer;" >
          <img src="../../../assets/icons/upload-cloud-01.svg" alt="upload" title="העלאת קובץ"  style="opacity: 0.6;">
          <input type="file" #signature_fileUpload style="display: none" (change)="onSelectedFileToUpload(signature_fileUpload,'signature_file')"
          accept=".jpg, .png, .pdf, .jpeg">
          </a>  

          </div>  
        </div>                   
          
        </div>    
        <div *ngIf="data.user.isAdmin && !(data.user.id === '3')" class="col">
          <label class="w-100 mb-0 fs-9">
            אישור קבצים
          </label>
            <button 
              (click)="onClickAttApproveFiles()"
              type="button"
              [disabled] = "!attApproveFilesButtonEnabled"
              class="btn btn-primary px-2 py-1 w-100"
              style="background-color: #ee2768; color: white; border-color: #ee2768;"
              >{{ attApproveFilesButtonText }}
            </button>
          
        </div>                                  
      </div>
      <div class="row">
        <div class="col-12 flex-fill line my-2"></div>
      </div>
      <div class="row">
      <div class="col-3">
          <label class="w-100 mb-2 fs-9">
            ברוטו
            <input 
              readonly 
              value="{{this.data.slikaEvent.amountGross | number}} ₪"
              
              class="form-control" 
              />
          </label>
        </div>
        <div class="col-3">
          <label class="w-100 mb-2 fs-9">
            עמלת איגוד הסליקה
            <input 
              readonly
              value="{{this.data.slikaEvent.slikaCommission | number}} ₪"

              class="form-control" 
              />
          </label>
        </div>
        <div  class="col-3">
          <label class="w-100 mb-2 fs-9">
            עמלת אטנדינג
            <input 
              readonly
              
              value="{{this.data.slikaEvent.attCommission | number}} ₪"
              class="form-control" 
              />
          </label>
        </div>
        <div class="col-3">
            <label class="w-100 mb-2 fs-9">
              נטו
              <!-- in the input: style="background-color: transparent; border: none;" -->
              <input 
                readonly 
                value="{{this.data.slikaEvent.amountNet | number}} ₪"
                class="form-control" 
                />
            </label>
        </div>   
      </div>
      <div class="row">
        <div class="col-12 flex-fill line my-2"></div>
      </div>
      <div class="row">
        <div class="col-4">
          <label class="w-100 mb-2 fs-9">
            מס' ספק
            <input formControlName="supplierNum" class="form-control" [class.is-invalid]="supplierNum.invalid" />
          </label>
        </div>
        <!-- <div class="col-4">
          <label class="w-100 mb-2 text-center fs-9">
            סטטוס העברה
          </label>
          <div class="text-center">
            <label class="switch">
              <input type="checkbox" formControlName="transactionStatus" (ngModelChange)="onToggleValueChange($event)">
              <span class="slider round"></span>
            </label>
          </div>
        </div> -->
  
        <div class="col-4">
          <label class="w-100 mb-2 fs-9">
            אסמכתה
            <input formControlName="referenceNum" class="form-control" [class.is-invalid]="referenceNum.invalid"/>
          </label>
        </div>  
        <div class="col-4 d-flex">
          <!-- inside label: (click)="transactionDatePicker.open()" -->
            <label class="w-100 mb-2 fs-9" (click)="referenceNumDatePicker.open()">
              תאריך ושעת אסמכתה
              <!-- inside the input, if using dateValidator: [class.is-invalid]="(referenceNumDate.hasError('invalidDate') || referenceNumDate.invalid) && isSubmitted" -->
              <input 
                [class.bg-white]="!referenceNumDate.disabled" 
                [class.bg-grey]="referenceNumDate.disabled" 
                readonly 
                formControlName="referenceNumDate" 
                [class.is-invalid]="referenceNumDate.invalid && isSubmitted" 
                class="form-control" 
                [matDatepicker]="referenceNumDatePicker" 
              />
            </label>
            <mat-datepicker #referenceNumDatePicker></mat-datepicker>
            <!-- <img src="../../../assets/icons/calendar-date.svg" class="px-2" style="opacity: 0.5;"> -->
          </div>               
      </div>
      <div class="row">
        <div class="col-4 ">
          <!-- inside label: (click)="transactionDatePicker.open()" -->
            <label class="w-100 mb-2 fs-9">
              תאריך העברה משוער
              <input readonly formControlName="transactionDate" class="form-control" [matDatepicker]="transactionDatePicker" />
            </label>
            <mat-datepicker #transactionDatePicker></mat-datepicker>
            <!-- <img src="../../../assets/icons/calendar-date.svg" class="px-2" style="opacity: 0.5;"> -->
          </div>
          <div class="col-4">
            
          </div>
          <div class="col-4 d-flex align-items-center">
              <!-- <label class="w-100 mb-2 fs-9">
                נטו -->
                <select [class.is-invalid]="referenceNumMinute.invalid && isSubmitted" formControlName="referenceNumMinute" class="form-control">
                  <option *ngFor="let minute of minutes" [value]="minute">{{minute}}</option>
                </select>
                <span class="mx-2">:</span>
                <select [class.is-invalid]="referenceNumHour.invalid && isSubmitted" formControlName="referenceNumHour" class="form-control">
                  <option *ngFor="let hour of hours" [value]="hour">{{hour}}</option>
                </select>
                
                <img src="../../../assets/icons/clock.svg" class="px-2">
              <!-- </label> -->
          </div>   
        </div>      
        <div class="row d-flex justify-content-between">
          <div class="col-4">
            <button type="button" mat-dialog-close class="btn btn-danger">
              ביטול
            </button>
          </div>
          <div *ngIf="data.user.isAdmin" class="col-4 d-flex justify-content-center">
            <button [disabled]="!slikaCanEdit" type="button" class="btn btn-primary" (click)="onClickEnableEdit()">
              עריכה
            </button>
          </div>
          <!-- <div *ngIf="data.user.isAdmin" class="col-4 d-flex justify-content-center">
            <button [disabled]="!adminCanEdit" type="button" class="btn btn-primary" (click)="onClickAdminUpdate()">
              עריכה
            </button>
          </div>           -->
          <div class="col-4">
            <button [disabled]="!isSubmitEnabled" type="submit" class="btn btn-primary float-end">
              עדכן
            </button>
            <!-- <button type="button" (click)="onClickCreatePassword()" class="btn btn-info float-end me-2">צור סיסמה</button> -->
          </div>
        </div>
    </div>
  </form>
  