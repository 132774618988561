import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { map } from 'rxjs/operators';
import { SlikaEventsGQL, SlikaEventsQuery } from '../../../../generated/graphql';

@Injectable({
  providedIn: 'root',
})
export class SlikaEventsResolver implements Resolve<SlikaEventsQuery['slikaEvents']> {
  constructor(private readonly slikaEventsGQL: SlikaEventsGQL) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //console.log("resolving in core/event/slika-events.resolver.ts");
    return this.slikaEventsGQL.fetch().pipe(map(({ data }) => data.slikaEvents));
  }
}
