import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreateTokenGQL } from '../../../generated/graphql';
import { UserService } from '../../core/services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.scss'],
})
export class AuthLoginComponent implements OnInit {
  loginForm: FormGroup;
  showError = false;
  count = 0;
  constructor(
    private readonly fb: FormBuilder,
    private readonly createTokenGQL: CreateTokenGQL,
    private readonly userService: UserService,
    private readonly router: Router
  ) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      rememberMe: false,
    });
  }

  ngOnInit(): void {}

  get email() {
    return this.loginForm.get('email')!;
  }

  get password() {
    return this.loginForm.get('password')!;
  }

  onSubmitLoginForm() {
    if (!this.loginForm.valid) {
      for (const controlKey of Object.keys(this.loginForm.controls)) {
        this.loginForm.controls[controlKey].markAsDirty();
      }
      return;
    }
    const data = this.loginForm.value;
    this.createTokenGQL
      .mutate({
        createAuthInput: { password: data.password, email: data.email },
      })
      .subscribe(
        ({ data: ret }) => {
          this.userService.setToken(ret!.createToken, data.rememberMe);
          this.router.navigateByUrl('/event');
        },
        () => {
          this.showError = true;
          this.count++;
        }
      );
  }
}
