<div *ngIf="user.isAdmin" class="container">
    <div class="row">
      <div class="col">
        <div class="card shadow-psd border-0 mt-5">
          <div class="card-body rounded-4">
            <div class="d-flex justify-content-between">
              <div>
              
              <h1
                class="font-FbMatador-Bold"
                style="font-size: 37px;"
              >
                אירועי מתנות באשראי
              </h1>
              <div class="mt-2 mb-3 d-flex align-items-center">
                <input type="text" [(ngModel)]="filterQuery" (input)="updateDisplayedSlikaEvents()" placeholder="מס' אירוע, מס' ספק או שם" 
                /> <img class="ps-2 pe-2" src="../../assets/icons/search-sm.svg" alt=" אירוע או מס' ספק">
              </div>
            </div>
              <div *ngIf="user.isAdmin && !(user.id === '3')" class="p-2">
                <button 
                (click)="onClickEditCommissions()" 
                type="button" 
                class="btn btn-primary">טבלת עמלות</button>

                <button 
                (click)="onClickGotoEventComponent()" 
                type="button" 
                class="btn btn-primary ms-3">חזרה לאירועים</button>
              </div>
              <!-- <div class="d-none">
                <img
                  class="cursor-pointer me-3"
                  src="/assets/icons/excel.svg"
                  alt="excel"
                />
                <img
                  class="cursor-pointer"
                  src="/assets/icons/print.svg"
                  alt="הדפסה"
                />
              </div> -->

            </div>
            <table class="table" style="border-collapse: unset">
              <thead class="fw-500">
                <tr>
                  <td style="cursor: pointer;" (click)="onSort('transactionStatus')" [class.sorted]="isSorted('transactionStatus')">סטטוס<br>העברה</td>
                  <td style="cursor: pointer;" (click)="onSort('eventType')" [class.sorted]="isSorted('eventType')">סוג האירוע</td>
                  <td style="cursor: pointer;" (click)="onSort('accountHolderName')" [class.sorted]="isSorted('accountHolderName')">שם בעל החשבון</td>
                  <td style="cursor: pointer;" (click)="onSort('name')" [class.sorted]="isSorted('name')">אירוע של</td>
                  <td style="cursor: pointer;" (click)="onSort('eventId')" [class.sorted]="isSorted('eventId')">מס' אירוע</td>
                  <td style="cursor: pointer;" (click)="onSort('supplierNum')" [class.sorted]="isSorted('supplierNum')">מס' ספק</td>
                  <td style="cursor: pointer;" (click)="onSort('subTerminal')" [class.sorted]="isSorted('subTerminal')">מסוף בן</td>
                  <td style="cursor: pointer;" (click)="onSort('referenceNum')" [class.sorted]="isSorted('referenceNum')">אסמכתה</td>
                  
                  
                  <td style="cursor: pointer;" (click)="onSort('eventDate')" [class.sorted]="isSorted('eventDate')">מועד האירוע</td>
                  <td style="cursor: pointer;" (click)="onSort('transactionDate')" [class.sorted]="isSorted('transactionDate')">מועד העברה</td>
                  <!-- <td class="text-center" style="cursor: pointer;" (click)="onSort('amountGross')" [class.sorted]="isSorted('amountGross')">ברוטו</td>
                  <td class="text-center" style="cursor: pointer;" (click)="onSort('commissionsTotal')" [class.sorted]="isSorted('commissionsTotal')">עמלות</td>
                  <td class="text-center" style="cursor: pointer;" (click)="onSort('amountNet')" [class.sorted]="isSorted('amountNet')">נטו</td> -->
                  <td></td>
                  
                </tr>
              </thead>
              <tbody class="fw-normal">
                <tr *ngFor="let slikaEvent of displayedSlikaEvents">
                  <td>
                    <img *ngIf="slikaEvent.transactionStatus" src="./../../assets/icons/ok.svg" alt="העברה בוצעה" >
                    <img *ngIf="!slikaEvent.transactionStatus" src="./../../assets/icons/x.svg" alt="העברה טרם בוצעה" >
                    <!-- <ng-template #transactionNo>
                    </ng-template> -->
                    
                    
                    <!-- <label class="switch">
                      <input type="checkbox" [ngModel]="slikaEvent.transactionStatus" (ngModelChange)="onToggleValueChange($event)">
                        <span class="slider round"></span>
                      </label> -->
                  </td>
                  <td>{{ slikaEvent.eventType }}</td>
                  <td>{{ slikaEvent.accountHolderName }}</td>
                  <td>{{ slikaEvent.name }}</td>
                  <td>{{ slikaEvent.eventId }}</td>
                  <td>{{ slikaEvent.supplierNum }}</td>
                  <td>{{ slikaEvent.subTerminal }}</td>
                  <td>{{ slikaEvent.referenceNum }}</td>
                  <td>{{ slikaEvent.eventDate | date:'dd/MM/yyyy' }}</td>
                  <td>{{ slikaEvent.transactionDate | date:'dd/MM/yyyy' }}</td>
                  <!-- <td>{{ slikaEvent.amountGross }}</td>
                  <td>{{ slikaEvent.commissionsTotal }}</td>
                  <td>{{ slikaEvent.amountNet }}</td> -->
                  <td>
                    <button *ngIf="user.isAdmin" (click)="onClickEditSlikaEvent(slikaEvent)" type="button" class="btn btn-primary">עריכה</button>
                  </td>
                  <!-- <td>
                    <a [routerLink]="[event.id, 'guest-list']" class="btn btn-primary">צפייה ברשימת המוזמנים</a>
                    <button *ngIf="user.isAdmin" (click)="onClickEditEvent(event)" type="button" class="btn btn-primary ms-3">עריכה</button>
                  </td> -->
                </tr>
              </tbody>
            </table>
            <div class="d-flex justify-content-between align-items-center mt-4">
              <div class="ps-2">מציג אירועים: {{pageIndex*pageSize+1}} - {{getEndIndex()}} מתוך {{slikaEvents.length}}</div>
              <div>
                <button type="button" class="btn btn-primary p-0" (click)="firstPage()" style="color: #132242; border-color: #d6edf5; background-color: #d6edf5;">
                  <img src="../../assets/icons/skip-right.svg" alt="עמוד ראשון"><!--  style="filter: invert(1);" -->
                </button>
                <button type="button" class="btn btn-primary ms-3 p-0" (click)="previousPage()" style="color: #132242; border-color: #d6edf5; background-color: rgb(214, 237, 245);">
                  <img src="../../assets/icons/chevron-right.svg" alt="עמוד קודם">
                </button>
                <span class="ps-2 mx-3">עמוד {{pageIndex + 1}} מתוך {{getPagesNum()}}</span>
                <button type="button" class="btn btn-primary ms-2 p-0" (click)="nextPage()" style="color: #132242; border-color: #d6edf5; background-color: rgb(214, 237, 245);">
                  <img src="../../assets/icons/chevron-left.svg" alt="עמוד הבא">
                </button>
                <button type="button" class="btn btn-primary ms-3 p-0" (click)="lastPage()" style="color: #132242; border-color: #d6edf5; background-color: rgb(214, 237, 245);">
                  <img src="../../assets/icons/skip-left.svg" alt="עמוד אחרון">
                </button>
                

              </div>
              <div class="pe-2">
                מס' אירועים בעמוד: 
                <select [(ngModel)]="pageSize" (change)="updateDisplayedSlikaEvents()">
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                </select>
              </div>              
          </div>
          
          </div>
        </div>
      </div>
    </div>
  </div>
  